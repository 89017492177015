import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import NavBar from "../components/navbar";
import Footer from "../components/footer";

import payroll from "../../assets/images/blogs/payroll.webp";
import growing from "../../assets/images/blogs/growing.webp";
import antimoney from "../../assets/images/blogs/anti-money.webp";
import roleaccounting from "../../assets/images/blogs/role-accounting.webp";
import uaeblog from "../../assets/images/blogs/uae-blog.webp";
import audituae from "../../assets/images/blogs/audit-uae.webp";
import bookkeeping from "../../assets/images/blogs/book-keeping.webp";
import claimvat from "../../assets/images/blogs/claim-vat.webp";
import disclosureuae from "../../assets/images/blogs/disclosure-uae.webp";
import blogimage from "../../assets/images/blogs/blogimage.webp";
import decodeblog from "../../assets/images/blogs/banner.webp";
import UnleashBusines from "../../assets/images/blogs/DUBAI FREE ZONE v2.webp";
import topTen from "../../assets/images/blogs/top-10-corporate-tax-counsultants-in-uae.webp";
import BenefitsOfHiring from "../../assets/images/blogs/benefits-of-hiring-tax-consultant-in-uae.webp";
import CorporateTaxPenalty from "../../assets/images/blogs/corporate_tax_penalty_of_aed_ten_thousand_for_late_tax_registration_in_uae.webp";
import { Helmet } from "react-helmet";

function BlogPage() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Helmet>
        <title>Our Blogs| Accruon Consultant LLC</title>
        <meta name="description" content="" />
        <link rel="canonical" href="https://accruonconsultants.com/Blogs" />
      </Helmet>
      {/* <NavBar></NavBar> */}

      <section class="main">
        {/* <div class="main"> */}

        <h2 class="left h1-b">
          <span>Our Blogs</span>
        </h2>

        <h1 class="h-blog">Latest Blog & Articles</h1>
        <ul class="cards">
          <li class="cards_item">
            <div class="card">
              <div class="card_image">
                <img src={CorporateTaxPenalty} alt="" class="img-blog" />
              </div>
              <h3 class="blog-h">
                Corporate Tax penalty of AED 10,000 for late tax registration in
                UAE!
              </h3>
              <div class="card-content">
                In the bustling landscape of business in the United Arab
                Emirates (UAE), staying abreast of regulatory changes is
                crucial. One recent development stirring the corporate realm is
                the implementation of a corporate tax penalty of AED 10,000 for
                late tax registration in the UAE.
              </div>
              <footer class="card-footer">
                <a
                  href="/blogs/corporate-tax-penalty-of-aed-ten-thousand-for-late-tax-registration-in-uae"
                  class="btn b-read"
                >
                  Read More
                </a>
              </footer>
            </div>
          </li>
          <li class="cards_item">
            <div class="card">
              <div class="card_image">
                <img src={BenefitsOfHiring} alt="" class="img-blog" />
              </div>
              <h3 class="blog-h">
                Benefits of Hiring Tax Consultant in UAE : Maximize Savings &
                Minimize Risk
              </h3>
              <div class="card-content">
                Are you worried about tax complexities? Do you know the benefits
                of hiring tax consultants in UAE? Tax policies may create
                ambiguity but partnering with Accruon simplifies the process.
              </div>
              <footer class="card-footer">
                <a
                  href="/blogs/benefits-of-hiring-tax-consultant-in-uae"
                  class="btn b-read"
                >
                  Read More
                </a>
              </footer>
            </div>
          </li>
          <li class="cards_item">
            <div class="card">
              <div class="card_image">
                <img src={topTen} alt="" class="img-blog" />
              </div>
              <h3 class="blog-h">Top 10 Corporate Tax Consultants in UAE</h3>
              <div class="card-content">
                The Ministry of Finance has released guidelines to assist
                companies in registering for corporate tax and obtaining
                relevant information, about the implementation of Corporate tax
                in UAE.
              </div>
              <footer class="card-footer">
                <a
                  href="/blogs/top-ten-corporate-tax-consultants-in-uae"
                  class="btn b-read"
                >
                  Read More
                </a>
              </footer>
            </div>
          </li>
          <li class="cards_item">
            <div class="card">
              <div class="card_image">
                <img src={UnleashBusines} alt="" class="img-blog" />
              </div>
              <h3 class="blog-h">
                Unleash Business Opportunities in UAE Free Zones
              </h3>
              <div class="card-content">
                Are you planning to set up a company/business in the free zone
                area in UAE? Do you need to know the tax exemptions and benefits
                of forming a company in a free zone in the UAE? We are here to
                provide you with the answer
              </div>
              <footer class="card-footer">
                <a
                  href="/blogs/unleash-Business-Opportunities"
                  class="btn b-read"
                >
                  Read More
                </a>
              </footer>
            </div>
          </li>
          <li class="cards_item">
            <div class="card">
              <div class="card_image">
                <img src={decodeblog} alt="" class="img-blog" />
              </div>
              <h3 class="blog-h">
                Decoding UAE Corporate Tax Law on “Natural Person”
              </h3>
              <div class="card-content">
                Are you confused with the term “Natural Person”? Do you belong
                to this category? Does UAE Corporate tax apply to you? Don’t
                worry, this blog will take you through the answers to these
                questions.
              </div>
              <footer class="card-footer">
                <a
                  href="/blogs/decoding-uae-corporate-tax-law"
                  class="btn b-read"
                >
                  Read More
                </a>
              </footer>
            </div>
          </li>
          <li class="cards_item">
            <div class="card">
              <div class="card_image">
                <img src={blogimage} alt="" class="img-blog" />
              </div>
              <h3 class="blog-h">
                {" "}
                Six accounting recommendations for small companies to start the
                new year
              </h3>
              <div class="card-content">
                Operating a small business in a cutthroat industry like Dubai is
                no easy task. There may be numerous ups and downs, similar to
                those on a roller coaster, and you may require wise counsel from
              </div>
              <footer class="card-footer">
                <a href="/blog-details" class="btn b-read">
                  Read More
                </a>
              </footer>
            </div>
          </li>

          <li class="cards_item">
            <div class="card">
              <div class="card_image">
                <img src={growing} alt="" class="img-blog" />
              </div>
              <h3 class="blog-h">Growing a business in the UAE</h3>
              <div class="card-content">
                Growing a business in the UAE (United Arab Emirates) can be an
                exciting opportunity due to its thriving economy and
              </div>
              <footer class="card-footer">
                <a
                  href="blogs/growing-a-business-in-the-uae"
                  class="btn b-read"
                >
                  Read More
                </a>
              </footer>
            </div>
          </li>
          <li class="cards_item">
            <div class="card">
              <div class="card_image">
                <img src={antimoney} alt="" class="img-blog" />
              </div>
              <h3 class="blog-h">Anti-money laundering laws (AML) in UAE</h3>
              <div class="card-content">
                The United Arab Emirates (UAE) has implemented comprehensive
                anti-money laundering (AML) Consulting firms in UAE laws and
                regulations to combat money laundering,
              </div>
              <footer class="card-footer">
                <a
                  href="/blogs/anti-money-laundering-laws-aml-in-uae"
                  class="btn b-read"
                >
                  Read More
                </a>
              </footer>
            </div>
          </li>

          <li class="cards_item">
            <div class="card">
              <div class="card_image">
                <img src={roleaccounting} alt="" class="img-blog" />
              </div>
              <h3 class="blog-h">ROLE OF ACCOUNTING IN IT BUSINESS</h3>
              <div class="card-content">
                Accounting Services in UAE plays a crucial role in IT businesses
                by providing financial information and ensuring the efficient
                management of
              </div>
              <footer class="card-footer">
                <a
                  href="/blogs/role-of-accounting-in-it-business"
                  class="btn b-read"
                >
                  Read More
                </a>
              </footer>
            </div>
          </li>

          <li class="cards_item">
            <div class="card">
              <div class="card_image">
                <img src={uaeblog} alt="" class="img-blog" />
              </div>
              <h3 class="blog-h">
                ALL YOU NEED TO KNOW ABOUT UAE CORPORATE TAX
              </h3>
              <div class="card-content">
                Corporate tax Introduction Corporate tax in UAE is a type of tax
                imposed on the profits or income earned by corporations, also
              </div>
              <footer class="card-footer">
                <a
                  href="/blogs/all-you-need-to-know-about-uae-corporate-tax"
                  class="btn b-read"
                >
                  Read More
                </a>
              </footer>
            </div>
          </li>
          <li class="cards_item">
            <div class="card">
              <div class="card_image">
                <img src={audituae} alt="" class="img-blog" />
              </div>
              <h3 class="blog-h">WHY AUDITING IS IMPORTANT IN UAE</h3>
              <div class="card-content">
                Auditing in the United Arab Emirates (UAE) follows the
                principles and standards of auditing commonly practiced
                internationally. Auditing in the UAE
              </div>
              <footer class="card-footer">
                <a
                  href="/blogs/why-auditing-is-important-in-uae"
                  class="btn b-read"
                >
                  Read More
                </a>
              </footer>
            </div>
          </li>

          <li class="cards_item">
            <div class="card">
              <div class="card_image">
                <img src={bookkeeping} alt="" class="img-blog" />
              </div>
              <h3 class="blog-h">
                PURPOSE OF BOOK KEEPING IN UNITED ARAB EMIRATES
              </h3>
              <div class="card-content">
                What is Bookkeeping in UAE ?Bookkeeping in the UAE refers to the
                process of recording, organizing, and maintaining accurate
                financial records
              </div>
              <footer class="card-footer">
                <a
                  href="/blogs/purpose-of-book-keeping-in-united-arab-emirates"
                  class="btn b-read"
                >
                  Read More
                </a>
              </footer>
            </div>
          </li>

          <li class="cards_item">
            <div class="card">
              <div class="card_image">
                <img src={claimvat} alt="" class="img-blog" />
              </div>
              <h3 class="blog-h">HOW TO CLAIM VAT REFUND IN UAE</h3>
              <div class="card-content">
                A VAT refund in UAE is a reimbursement of Value Added Tax (VAT)
                that has been paid on goods or
              </div>
              <footer class="card-footer">
                <a
                  href="/blogs/how-to-claim-vat-refund-in-uae"
                  class="btn b-read"
                >
                  Read More
                </a>
              </footer>
            </div>
          </li>
          <li class="cards_item">
            <div class="card">
              <div class="card_image">
                <img src={disclosureuae} alt="" class="img-blog" />
              </div>
              <h3 class="blog-h">IMPORTANCE OF VOLUNTARY DISCLOSURE IN UAE</h3>
              <div class="card-content">
                Voluntary disclosure in UAE is the act of providing information
                or data to others without being legally required to do
              </div>
              <footer class="card-footer">
                <a
                  href="/blogs/importance-of-voluntary-disclosure-in-uae"
                  class="btn b-read"
                >
                  Read More
                </a>
              </footer>
            </div>
          </li>
        </ul>
        {/* </div> */}
      </section>
      {/* <Footer></Footer> */}
    </>
  );
}

export default BlogPage;
