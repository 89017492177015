import React from "react";
import NavBar from "../../../components/navbar";
import Footer from "../../../components/footer";
import ContactSectionPages from "../../../components/contact";
import tax from "../../../../assets/images/services/Economic-Substance-Regulations.webp";
import sidearrow from "../../../../assets/images/side-arrow.webp";
import { Helmet } from "react-helmet";
function EconomicSubstanceRegulationsPage() {
  return (
    <>
      <Helmet>
        <title>Economic Substance Regulations | Accruon Consultant LLC </title>
        <meta
          name="description"
          content="Get familiarised with economic substance regulation in UAE with the help of our professionals who conduct assessments of company operations to assist you."
        />
        <link
          rel="canonical"
          href="https://accruonconsultants.com/services/economic-substance-regulations"
        />
      </Helmet>
      {/* <NavBar></NavBar> */}

      <section class="about container margin-top1">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-12 md-pb">
            <div class="title-wrapper align-left">
              <h2 class="left h1-b">
                <span>Service details</span>
              </h2>
              <h3 class="pb-3 about-h1">Economic Substance Regulations </h3>

              <p class="about-p1">
                &nbsp;The Economic Substance Regulations (ESR) were introduced
                in the United Arab Emirates (UAE) via Cabinet Decision Number 31
                of 2019, prompted by scrutiny from the European Union and the
                UAE's OECD membership. Both onshore and free zone businesses
                will fall under these regulations, aiming to curb tax evasion
                and ensure fair tax payments. The UAE government has replaced
                the old ESR laws with Cabinet Resolution No. 57 of 2020,
                effective from January 1, 2019, providing clarity on the updated
                regulations.
              </p>
              {/* <p class="about-p1">
                The UAE government has announced “Cabinet Resolution No. 57 of
                2020,” which will take the place of the old ESR laws. They will
                also assist in the clarification of the modified ES Regulations
                and will take effect on January 1, 2019.
              </p> */}
            </div>
          </div>

          <div class="col-lg-6 col-md-12 md-pb">
            <img
              src={tax}
              class="mob-img"
              alt="Economic Substance Regulations | Accruon Consultant LLC"
            />
          </div>

          <section class="about container">
            <div class="ar1">
              <span>
                {" "}
                How does the UAE's Economic Substance Regulations process
                benefit your company's growth?{" "}
              </span>
              <img class="img-arrow" src={sidearrow} alt="" />
            </div>
          </section>
        </div>
        <section class="about container d-c1">
          <div class="list-service">
            <ol>
              <li>
                {" "}
                <div class="arrow-triangle left"></div> Strategizing tax
                planning and structure
              </li>
              <li>
                {" "}
                <div class="arrow-triangle left"></div> Facilitating tax
                preparation
              </li>
              <li>
                {" "}
                <div class="arrow-triangle left"></div> Guiding through
                legislative changes' impact on business structure and adapting
                tax strategies accordingly
              </li>
              <li>
                {" "}
                <div class="arrow-triangle left"></div> Providing insights on
                tax implications of potential transactions{" "}
              </li>
              <li>
                {" "}
                <div class="arrow-triangle left"></div>Assisting in business
                reorganizations{" "}
              </li>
              <li>
                {" "}
                <div class="arrow-triangle left"></div> Reviewing tax
                implications of company agreements or legal documents
              </li>
              <li>
                {" "}
                <div class="arrow-triangle left"></div>Aiding in Corporate Tax
                Return filing{" "}
              </li>
            </ol>
          </div>
        </section>

        <section class="about container">
          <div class="ar1">
            <span> What Makes Us Unique </span>
            <img class="img-arrow" src={sidearrow} alt="" />
          </div>
        </section>
        {/* <div class="row justify-content-center">
                    <p class="about-p1">
                        <strong>
                          
                        </strong>
                    </p>
                </div> */}
        <section class="about container d-c1">
          <div class="list-service">
            <ol>
              <li>
                {" "}
                <div class="arrow-triangle left"></div>Conducting comprehensive
                assessments of company operations
              </li>
              <li>
                {" "}
                <div class="arrow-triangle left"></div> A dedicated team of
                professionals{" "}
              </li>
              <li>
                {" "}
                <div class="arrow-triangle left"></div> Tailoring excellent
                business solutions to your specific needs
              </li>
              <li>
                {" "}
                <div class="arrow-triangle left"></div> Extensive experience
                across various industries and business types{" "}
              </li>
            </ol>
          </div>
        </section>

        <div class="row justify-content-center">
          <p class="about-p1">
            <strong>
              <h3 class="pb-3 about-h1">
                {" "}
                How Accruon Consultant can assist your company??
              </h3>
            </strong>
          </p>
        </div>
        <p class="about-p1">
          The ESR will be enforced annually for all UAE enterprises.
          Consequently, organizations will need to address these reports yearly.
          Accruon’s professionals will familiarize themselves with your company
          and offer tailored solutions. We are equipped to analyze all your
          company's activities to ensure compliance with the law. For more
          information on ESR in the UAE, feel free to reach out to us.
        </p>
      </section>

      <ContactSectionPages />
      {/* <Footer></Footer> */}
    </>
  );
}

export default EconomicSubstanceRegulationsPage;
