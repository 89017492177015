import React from "react";
import Header from "./Header/Header";
import { Outlet } from "react-router-dom";
import { Footer } from "./Footer";

export const MainLayout = () => {
  return (
    <div style={{ width: "100%" }}>
      <Header />
      <main style={{ width: "100%" }}>
        <Outlet />
      </main>
      <Footer/>
    </div>
  );
};
