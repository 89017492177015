import React from "react";
import leftarrow from "../../../assets/images/home/left-arrow.webp";
import rightarrow from "../../../assets/images/home/right-arrow.webp";
import cleantel from "../../../assets/images/home/cleantel.webp";
import op3 from "../../../assets/images/home/op-3.webp";
import steelmart from "../../../assets/images/home/steel-mart.webp";
import "../style/testimonial.style.css";
import { NavLink } from "react-router-dom";
import { CarouselComponent } from "../../../shared/components/CarouselComponent";

export const ServTestimonials = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
      slidesToSlide: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 2,
      slidesToSlide: 2,
    },
    tablet: { breakpoint: { max: 1300, min: 880 }, items: 2, slidesToSlide: 2 },
    mobile: { breakpoint: { max: 880, min: 0 }, items: 1 },
  };

  const testimonialsData = [
    {
      img: cleantel,
      link: "https://cleantel.me/",
      text: "Working with “Accruon Consultant” has been a game-changer for our business. Their professionalism, deep industry knowledge, and commitment to delivering results are truly commendable. Accruon team of experts provide invaluable guidance and support, helping us streamline our operations, optimize our processes, and achieve significant cost savings. We highly recommend “Accruon Consultant” to any organization seeking top-notch consulting services.",
    },
    {
      img: op3,
      link: "https://op3global.com/",
      text: "I cannot thank “Accruon Consultant” enough for their exceptional consulting services. The dedication, expertise, and collaborative approach of the entire team have exceeded our expectations. Their strategic insights and innovative solutions have transformed our business. With their guidance, we were able to identify untapped opportunities, enhance our market positioning, and drive substantial growth.",
    },
    {
      img: steelmart,
      text: "Choosing Accruon Consultant was the best decision we made for our organization. They conducted a thorough analysis of Our business, identified areas for improvement, and provided practical recommendations. Their actionable strategies helped us overcome challenges, improve our efficiency, and achieve measurable results. We are incredibly grateful for their professionalism, integrity, and unwavering support throughout the engagement..",
    },
  ];

  return (
    <section className="pt-5 circle-1 main">
      <h2 className="left h2-b h2-b1">
        <span>Our Testimonials</span>
      </h2>
      <h2 className="h-blog">Client Testimonials on Our Services</h2>
      <div className="container mt-4">
        <CarouselComponent
          responsive={responsive}
          testimonials={true}
          viewDots={true}
          autoPlaySpeed={3400}
          autoPlay={true}
        >
          {testimonialsData.map((item, index) => (
            <div
              key={index}
              style={{ width: "400px" }}
              className="carasoulpage"
            >
              <img alt="" src={leftarrow} className="pb-4 comms" />
              <p className="text-muted text-m">{item.text}</p>
              <div>
                <img alt="" src={rightarrow} className="pb-4 comms arrow2" />
              </div>
              <div className="p1">
                <div className="p">
                  <div className="d-flex align-items-center pt-3">
                    <div className="author-img mr-3">
                      {item.link ? (
                        <NavLink to={item.link} target="_blank">
                          <img alt="" src={item.img} className="img-lo" />
                        </NavLink>
                      ) : (
                        <img alt="" src={item.img} className="img-lo" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </CarouselComponent>
      </div>
    </section>
  );
};


