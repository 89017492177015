const BlogSideBarPage=()=>{
    return (
      <>
        <aside class="col sidebar">
          <h2 class="side-head">Recent Posts</h2>

          <ul class="blog-list">
            <a href="/blogs/corporate-tax-penalty-of-aed-ten-thousand-for-late-tax-registration-in-uae">
              <li>
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                <span>
                  Corporate Tax penalty of AED 10,000 for late tax registration
                  in UAE!
                </span>
              </li>
            </a>
            <a href="/blogs/benefits-of-hiring-tax-consultant-in-uae">
              <li>
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                <span>
                  Benefits of Hiring Tax Consultant in UAE : Maximize Savings &
                  Minimize Risk
                </span>
              </li>
            </a>
            <a href="/blogs/top-ten-corporate-tax-consultant-in-uae">
              <li>
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                <span>Top 10 Corporate Tax Consultants in UAE</span>
              </li>
            </a>
            <a href="/blog-details">
              <li>
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                <span>
                  Six accounting recommendations for small companies to start
                  the new year
                </span>
              </li>
            </a>
            <a href="/blogs/growing-a-business-in-the-uae">
              {" "}
              <li>
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                <span>Growing a business in the UAE</span>
              </li>
            </a>
            <a href="/blogs/anti-money-laundering-laws-aml-in-uae">
              {" "}
              <li>
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                <span>Anti-money laundering laws (AML) in UAE</span>
              </li>
            </a>
            <a href="/blogs/role-of-accounting-in-it-business">
              {" "}
              <li>
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                <span>Role of accounting in It</span>
              </li>
            </a>
            <a href="/blogs/all-you-need-to-know-about-uae-corporate-tax">
              {" "}
              <li>
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                <span>UAE CORPORATE TAX</span>
              </li>
            </a>
          </ul>

          <br />
          {/* <h2 class="side-head">Categories</h2>


                        <ul class="blog-list">
                        <a href=""> <li><i class="fa fa-arrow-right" aria-hidden="true"></i><span>PAYROLL PROCESS AND MANAGEMENT IN UAE</span></li></a>
                        <a href="">  <li><i class="fa fa-arrow-right" aria-hidden="true"></i><span>Growing a business in the UAE</span></li></a>
                        <a href="">  <li><i class="fa fa-arrow-right" aria-hidden="true"></i><span>Anti-money laundering laws (AML) in UAE</span></li></a>
                        <a href="">  <li><i class="fa fa-arrow-right" aria-hidden="true"></i><span>Role of accounting in It</span></li></a>
                        <a href=""> <li><i class="fa fa-arrow-right" aria-hidden="true"></i><span>UAE CORPORATE TAX</span></li></a>
                        </ul> */}
        </aside>
      </>
    );
}
export default BlogSideBarPage;