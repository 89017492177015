import React from 'react'
import { TopSection } from './components/TopSection';
import { BottomSection } from './components/BottomSection';
const Header = () => {
  return (
    <React.Fragment>
      <TopSection />
      <BottomSection/>
    </React.Fragment>
  );
}

export default Header