import React from "react";
import NavBar from "../../../components/navbar";
import Footer from "../../../components/footer";

import tax from "../../../../assets/images/services/accounting-and-bookkeeping-services-in-uae.webp";
import ContactSectionPages from "../../../components/contact";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
function NationalInCountryValueProgramPage() {
  return (
    <>
      {/* <NavBar></NavBar> */}
      <Helmet>
        <title>
          National In-Country Value Program | Accruon Consultant LLC{" "}
        </title>
        <meta
          name="description"
          content="We provide you with insights into In-Country Value Certification, its significance, and a step-by-step process to obtain your ICV Certificate."
        />
        <link
          rel="canonical"
          href="https://accruonconsultants.com/services/national-in-country-value-program"
        />
      </Helmet>
      <section class="about container margin-top1">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-12 md-pb">
            <div class="title-wrapper align-left">
              <h2 class="left h1-b">
                <span>Service details</span>
              </h2>
              <h3 class="pb-3 about-h1">
                National In-Country Value Program (ICV Certification){" "}
              </h3>

              <p class="about-p1">
                <Link
                  to={
                    "https://www2.deloitte.com/xe/en/pages/audit/solutions/national-in-country-value-certification.html"
                  }
                  className="internal_link"
                >
                  The National In-Country Value (ICV) Certification Program
                </Link>
                , initiated by the UAE government, aims to enhance economic
                growth and bolster local industries by channeling a larger
                portion of public expenditure into the national economy. Through
                ICV, local supply chains are established to support the growth
                of small and medium-sized enterprises (SMEs), enhance the skills
                of the local workforce, and implement community initiatives that
                have a lasting positive impact. Recently, as part of the
                "Projects of the 50" initiative, the UAE government has
                introduced the ICV Program at a federal level under the
                oversight of the Ministry of Industry & Advanced Technology.
                This move is geared towards boosting the demand for local goods
                and services, fostering local capabilities, and attracting
                foreign direct investment to the domestic industrial sector. The
                ICV Program, which initially launched in Abu Dhabi in 2018, has
                seen significant success.-
              </p>
            </div>
          </div>

          <div class="col-lg-6 col-md-12 md-pb">
            <img src={tax} class="mob-img" alt="" />
          </div>
        </div>

        <section className="ab-sec">
          <div className="list-service">
            <ol>
              <li class="about-p1">
                <div class="arrow-triangle left"></div>
                Enhances opportunities for local suppliers to form partnerships
                with foreign firms
              </li>
              <li class="about-p1">
                <div class="arrow-triangle left"></div>
                Provides contractual advantages with partner companies and
                government entities
              </li>
              <li class="about-p1">
                <div class="arrow-triangle left"></div>Increases private sector
                involvement
              </li>
              <li class="about-p1">
                <div class="arrow-triangle left"></div>Facilitates GDP
                diversification
              </li>
              <li class="about-p1">
                <div class="arrow-triangle left"></div>
                Encourages the localization of critical supply chain components
              </li>
            </ol>
          </div>
        </section>

        <section className="ab-sec">
          <p class="about-p1">
            It's worth noting that the ICV program in the UAE is continually
            evolving, currently in its third version. While not directly
            impacting ICV scores, the corporate social responsibility (CSR)
            activities of suppliers in the UAE are documented within the ICV
            framework, emphasizing the importance of long-term relationships in
            the region's business landscape. Rather than viewing ICV scores in
            isolation, it's essential to see them as part of an ongoing
            partnership between suppliers and their respective countries to
            enhance economic sustainability in the Middle East.
          </p>
        </section>
      </section>

      <ContactSectionPages />
      {/* <Footer></Footer> */}
    </>
  );
}

export default NationalInCountryValueProgramPage;
