import React from 'react'
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom'

export const AboutUs=()=> {
    return (
      <>
        <section class="about main">
          <NavLink to="/About-Us">
            {" "}
            <h2 class="left h2-b">
              <span>About us</span>
            </h2>
          </NavLink>
          <h1 class="about-h h_home">Tax Consultants in UAE. </h1>

          <p class="about-p">
            Accruon Consultant LLC is a trusted{" "}
            <Link
              to={"/services/corporate-tax-in-uae"}
              className="internal_link"
            >
              tax consultants
            </Link>{" "}
            in UAE. We are a team of professional Chartered accountants, cost
            and management accountants, and software experts who specialize in
            providing comprehensive tax consulting services in UAE. We have over
            20 years of expertise in the industry and are well-equipped to
            handle all the tax-related needs in the UAE. Our vision is to emerge
            as the foremost tax consultants in UAE, in terms of providing
            professional services with absolute customer satisfaction. The
            recent economic shift in the UAE, including the implementation of
            taxes and the exclusion of tax-free zones, has made it essential to
            understand tax laws. At Accruon, we offer expert tax guidance and
            support to help you stay compliant with local laws and international
            tax standards.
            <Link to={"/Our-Team"} style={{ color: "rgb(66 66 66)" }}>
              Our team
            </Link>{" "}
            has an in-depth understanding of tax laws applicable to every
            industrial sector, making us one of the best and most trusted tax
            consultants in UAE. As well-known tax consultants in UAE, we assist
            you in the preparation of monthly and yearly tax returns, recording
            files for documentation and maintenance, and keeping you updated
            about tax laws, regulations, and practices. Apart from our
            professional tax consulting services in UAE, we also provide other
            services such as{" "}
            <Link
              to={"/services/vat-consultants-in-uae"}
              // style={{ color: "rgb(66 66 66)" }}
              className="internal_link"
            >
              VAT consulting
            </Link>
            ,
            <Link
              to={"/services/audit-and-assurance-service-in-uae"}
              className="internal_link"
            >
              Audit and Assurance services
            </Link>
            ,
            <Link
              to={"/services/accounting-and-bookkeeping-services"}
              className="internal_link"
            >
              accounting and bookkeeping services
            </Link>
            ,
            <Link
              to={"/services/excise-tax-related-services"}
              className="internal_link"
            >
              excise tax-related services
            </Link>
            ,{" "}
            <Link
              to={"/services/management-consultancy-services"}
              className="internal_link"
            >
              management consultancy services
            </Link>
            , payroll services, and{" "}
            <Link to={"/Services"} className="internal_link">
              much more
            </Link>
            . Whether you require auditing, accounting, or financial advisory
            services our professional tax advisors are equipped to provide
            solutions according to your needs. We offer round-the-clock customer
            assistance and our tax advisors are dedicated to helping clients
            accomplish their business objectives. Our team provides
            comprehensive knowledge of tax regulations and valuable advice to
            minimize tax liabilities and maximize tax resources for businesses.
            Whether you're a small business owner, a multinational corporation,
            or an individual taxpayer, our tax consulting services are here to
            help you. You can trust that your financial matters are safe in our
            hands as we are committed to delivering transparent, honest, and
            reliable services. Navigating the complexities of the UAE tax system
            requires a dedicated and experienced partner. At Accruon Consultant
            LLC, we are committed to providing you with comprehensive and
            personalized tax services to help you achieve your business goals.
          </p>

          <div class="div-1">
            <div class="division">
              <ul>
                <li class="tick1">
                  <span>Team of experts</span>
                </li>
                <li class="tick1">
                  <span>Opportunity for Significant Cost Reductions.</span>
                </li>
                <li class="tick1">
                  <span>Round-the-clock customer assistance</span>
                </li>
                <li class="tick1">
                  <span>Analytical thinkers with problem-solving ability</span>
                </li>
              </ul>
            </div>
            <div class="division">
              <ul>
                <li class="tick1">
                  <span>Detail oriented approach</span>
                </li>
                <li class="tick1">
                  <span>Gain insights from customer input</span>
                </li>
                <li class="tick1">
                  <span>Ability to analyze and interpret data</span>
                </li>
                <li class="tick1">
                  <span>
                    Review the current system to offer more efficient solutions.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </>
    );
}
