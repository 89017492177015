import React from 'react'

export const CompanyLogo =React.memo(({image}) => {
  return (
    <React.Fragment>
      <a href="/" className="logo">
        <img src={image} alt="Accruon Consultant LLC" />
      </a>
    </React.Fragment>
  );
})
