import React from 'react'
import Phonecall from "../../../assets/images/phone11.webp";

export const PhoneNo = () => {
const phoneNumbers = [
  { number: "+971 52 913 7700", url: "tel:+971529137700" },
  { number: "+971 52 630 4982", url: "tel:+971526304982" },
];
  return (
    <React.Fragment>
      {phoneNumbers.map(({ number, url }) => (
        <div key={number} className="foot-ic">
          <div>
            <img alt="" src={Phonecall} />
          </div>
          <div>
            <a href={url} target="_blank">
              {number}
            </a>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
}
