import React from 'react'
import { NavLink } from 'react-router-dom';

export const Dropdown = ({ title, contents }) => {
  return (
    <React.Fragment>
      <div className="dropdown">
        <NavLink to="#">{title}</NavLink>
        <div className="dropdown-content">
                  {contents?.length > 0 ? contents.map((item, index) => <a key={index} href={item?.link}>{item?.head }</a>):null}
                  </div>
      </div>
    </React.Fragment>
  );
};
