import React from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import NavBar from '../components/navbar'
import Footer from '../components/footer'

import rijask from "../../assets/images/about/rijas-k.webp"
import bijukn from "../../assets/images/about/biju-kn.webp"
import Rasmig from "../../assets/images/about/Rasmi-g.webp"
import anoopp from "../../assets/images/about/anoop-p.webp"
import raveendranp from "../../assets/images/about/raveendran-p.webp"
import { Helmet } from 'react-helmet'

function OurTeamsPage() {
    const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return (
      <>
        <Helmet>
          <title>Our Team| Accruon Consultant LLC</title>
          <meta name="description" content="" />
          <link
            rel="canonical"
            href="https://accruonconsultants.com/Our-Team"
          />
        </Helmet>
        {/* <NavBar></NavBar> */}
        <section className="main">
          <h2 class="left h1-b">
            <span>Our Team</span>
          </h2>

          <h1 class="h-blog">MEET THE EXPERTS</h1>
          <div class="row">
            <div class="col-md-6 side1">
              <div class="l-side">
                <div class="team-div">
                  <img src={rijask} class="team-img" alt="" />
                  <span class="team-name">
                    <h4>RIJAS KOCHUNNI</h4>
                    <h6>M.Com, FCA</h6>
                  </span>
                </div>
                <p>
                  Rijas has nearly 20 years of experience working in compliance,
                  assurance, taxation, and financial consultancy. After
                  qualifying as a Chartered Accountant he worked in the industry
                  for about five years, after which he established his practice.
                  The firm, K Rijas & Associates, quickly grew in stature and
                  emerged as one of the leading Chartered Accountant firms in
                  South India. Within 10 years of existence, the firm shifted
                  its operations to its own corporate office located in Kochi,
                  Kerala. Having an established enterprise, he fully understands
                  the various nuances of operating a business. This inherent
                  quality of his adds a lot of value to the customers as all
                  solutions are vetted and customer-friendly through his office.
                  He has led various teams in taxation-related matters such as
                  VAT, GST, Service tax, etc. He has also given enumerable
                  presentations and taken training sessions in this regard. He
                  is our principal consultant in all taxation-related matters,
                  business advisory services, corporate finance, capital
                  structuring, etc.
                </p>
              </div>
            </div>

            <div class="col-md-6 side1">
              <div class="l-side">
                <div class="team-div">
                  <img src={bijukn} class="team-img" alt="" />
                  <span class="team-name">
                    <h4>BIJU K N</h4>
                    <h6>B.COM, FCA</h6>
                  </span>
                </div>
                <p>
                  Biju is a fellow member of the Institute of Chartered
                  Accountants of India and possesses over 20 years of
                  experience. After a brief stint in industry, Biju shifted his
                  entire focus to setting up and scaling up his practice in
                  Kochi, Kerala. Over the years, the firm has witnessed
                  exponential growth directly attributable to the dedicated
                  service extended by him. He advises a large number of
                  owner-managed business entities on accounting, taxation, and
                  finance matters. He has particular expertise in new business
                  startups, acquisitions, fundraising, debt restructuring, and
                  business negotiations particularly in business disposals and
                  exit strategies. Furthermore, he chairs audit committees of
                  various closely held organizations and non-profit
                  organizations. He also takes care of quality control and staff
                  development of the firm.
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 side1">
              <div class="l-side">
                <div class="team-div">
                  <img src={Rasmig} class="team-img" alt="" />
                  <span class="team-name">
                    <h4>RASMI G NAIR</h4>
                    <h6>B.COM, FCA</h6>
                  </span>
                </div>
                <p>
                  Rasmi G Nair is a dynamic Chartered Accountant with more than
                  18 years of experience in the fields of accounts, finance,
                  auditing, and taxation. She has been in the field of Chartered
                  Accountancy from the year 2006 onwards. Driven by the flair
                  for teaching, she always engaged in the training for
                  accounting and finance students. She was the faculty of
                  various universities and institutes for MBA and other
                  professional courses. Not only that, she also has rich and
                  quality experience in the field of accounts, audits, finance,
                  review assignments, taxation, GST matters, etc. She is an
                  effective and accomplished team leader with sharpened soft
                  skills and technical knowledge.
                </p>
              </div>
            </div>

            <div class="col-md-6 side1">
              <div class="l-side">
                <div class="team-div">
                  <img src={raveendranp} class="team-img" alt="" />

                  <span class="team-name">
                    <h4>RAVEENDRAN P</h4>
                    <h6>B.SC, FCA, DISA</h6>
                  </span>
                </div>
                <p>
                  Raveendran is a finance professional with a strong accounts
                  background and proven abilities with an independent
                  disposition, and exceptional analytical and administrative
                  skills holding more than 20 years of progressive experience in
                  finance, accounts, auditing, and taxation fields. Having
                  qualified as a chartered accountant and a Certified
                  Information Systems Auditor (DISA) from The Institute Of
                  Chartered Accountants Of India, he possesses hands-on
                  experience and ability both in the industry sector and
                  independent professional practice. His industrial exposure
                  includes international taxation, accounts, and finance matters
                  of a group of companies in the Middle East in the fields of
                  oil trading, shipping, general trading, and hospital groups,
                  and has specific experience in the business of seafood and
                  processed food export, construction, tours & travels, capital
                  market, commodity market, and insurance broking firms, etc.
                  His independent professional practice is spread across India
                  and the Middle East, in the fields of accounts, auditing,
                  International Taxation and VAT Consultancy, etc.
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 side1">
              <div class="l-side">
                <div class="team-div">
                  <img src={anoopp} class="team-img" alt="" />

                  <span class="team-name">
                    <h4>ANOOP PADMAKUMAR</h4>
                    <h6>B.Com, ACA, ACMA, DISA, CISA</h6>
                  </span>
                </div>

                <p>
                  Having qualified both as a cost accountant and as a chartered
                  accountant by the age of 23, Anoop worked in various parts of
                  the world including Africa, the Middle East, and India. He
                  went on to add another double qualification in system audit –
                  a Certified Information Systems Auditor (CISA) from the
                  Information Systems Audit and Control Association, Illinois,
                  USA, and a Diploma in Information Systems Audit from ICAI,
                  India. He possesses a hands-on attitude and gets deeply
                  involved in all matters undertaken by him. Being an expert in
                  Microsoft Applications, he constantly motivates people to
                  achieve beyond their full capacity. Working across industries,
                  his exposure includes trading, manufacturing, contracting,
                  construction, dairy and dairy products, real estate, process
                  industries, education, tours and travels, EPC contracting,
                  consultancy, auditing, etc. He is our principal consultant in
                  formulating manuals like accounting manuals, business
                  restructuring, systems implementation including IT systems,
                  building cost control mechanisms, etc.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* <Footer></Footer> */}
      </>
    );
}

export default OurTeamsPage