import React, { useRef } from "react";
import { Carousel } from "antd";
import { NavLink } from "react-router-dom";
import { CarouselComponent } from "../../../shared/components/CarouselComponent";
import "../style/topclient.style.css";

import karima from "../../../assets/images/home/karima.webp";
import filtrec from "../../../assets/images/home/filtrec.webp";
import visionexports from "../../../assets/images/home/vision-exports.webp";
import cornation from "../../../assets/images/home/cornation.webp";
import cleantel from "../../../assets/images/home/clean-tel.webp";
import autoplus from "../../../assets/images/home/autoplus.webp";
import steelmart from "../../../assets/images/home/steel-mart.webp";
import lattafa from "../../../assets/images/home/lattafa.webp";
import csc from "../../../assets/images/home/csc.webp";
import albarakahdates from "../../../assets/images/home/al-barakah-dates.webp";
import starfluid from "../../../assets/images/home/star-fluid.webp";
import rawahi from "../../../assets/images/home/rawahi.webp";
import op3 from "../../../assets/images/home/op-3.webp";

export const TopClients=()=> {
  const clientData = [
    { image: karima, link: null },
    { image: filtrec, link: "https://www.filtrec.com/" },
    { image: visionexports, link: null },
    { image: cornation, link: "https://www.coronation.com/en/institutional/" },
    { image: cleantel, link: "https://cleantel.me/" },
    { image: autoplus, link: null },
    { image: steelmart, link: null },
    { image: lattafa, link: null },
    { image: csc, link: "https://www.confidenceshipping.com/" },
    { image: albarakahdates, link: "https://albarakahdatesfactory.com/" },
    { image: starfluid, link: "https://stardf.com/index.html" },
    { image: rawahi, link: null },
    { image: op3, link: "https://op3global.com/" },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 5,
      slidesToSlide: 1,
    },
    tablet: { breakpoint: { max: 1300, min: 880 }, items: 5, slidesToSlide: 1 },
    mobile: { breakpoint: { max: 880, min: 0 }, items: 5 },
  };

  return (
    <div className="bg-c">
      <div className="main">
        <h2 className="left h2-b h2-b1 left-heading h2-b1">
          <span>Our Top Clients</span>
        </h2>
        <div className="main-feature"></div>
        <h2 className="h-blog">Featured Clients</h2>
        <br />
        <div className="main" id="main-f">
          <CarouselComponent
            autoPlay={true}
            responsive={responsive ? responsive : {}}
          >
            {clientData.map((client, index) => (
              <div key={index}>
                {client.link ? (
                  <NavLink to={client.link} target="_blank">
                    <img src={client.image} alt="" />
                  </NavLink>
                ) : (
                  <img src={client.image} alt="" />
                )}
              </div>
            ))}
          </CarouselComponent>
        </div>
      </div>
    </div>
  );
}


