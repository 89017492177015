import React from "react";
import { AboutUs, BannerComponent, BlogStart, ServTestimonials, Service, Strip, TopClients } from ".";
import PageHelmet from "../../shared/components/ReactHelmet";
import { UseScrollToTop } from "../../shared";

export const Home = React.memo(() => {
  UseScrollToTop()
    return (
      <React.Fragment>
        <PageHelmet
          title={"Tax Consultants in UAE | Accruon Consultant LLC"}
            description={"Accruon Consultant LLC is a leading Tax Consultants in UAE with a team of professional chartered accountants, cost and management accountants"}
          />
        <BannerComponent />
        <AboutUs />
        <Strip />
        <Service />
        <ServTestimonials />
        <TopClients />
        <BlogStart />
      </React.Fragment>
    );
});


