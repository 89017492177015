import React from "react";
import coparate from "../../../assets/images/services/coparate.webp";
import vaticon from "../../../assets/images/services/vat-icon.webp";
import audit from "../../../assets/images/services/audit.webp";
import icv from "../../../assets/images/services/icv .webp";
import { useNavigate } from "react-router-dom";

const servicesData = [
  {
    title: "Corporate Tax in UAE",
    image: coparate,
    link: "/services/corporate-tax-in-uae",
    content:
      "Corporate tax is a type of direct tax applied on a corporation’s or other business’s net revenue or profit. The…",
  },
  {
    title: "VAT Consultants in UAE",
    image: vaticon,
    link: "/services/vat-consultants-in-uae",
    content:
      "Top Listed Audit Firms in Dubai and the Northern Emirates of the UAE provide financial statement auditing…",
  },
  {
    title: "Audit & Assurance",
    image: audit,
    link: "/services/audit-and-assurance-service-in-uae",
    content:
      "One of the fundamental services that every organisation requires to keep track of their financial transactions is audit and assurance…",
  },
  {
    title: "National In-Country Value Program (ICV)",
    image: icv,
    link: "/services/national-in-country-value-program",
    content:
      "The National In-Country Value (ICV) Program is a UAE government program that aims to boost economic performance and support local…",
  },
];
export const Service=()=> {
    const navigate = useNavigate();
     const handleServiceClick = (link) => {
       navigate(link);
     };
  return (
    <>
      <section className="pt-5 circle-1 main">
        <h2 className="left h2-b h2-b1">
          <span>Our Services</span>
        </h2>
        <h2 className="h-blog">We Offer Quality Services</h2>
        <div className="a-tag">
          <a href="/services">
            <h2 className="open-page">View All</h2>
          </a>
        </div>
        <div className="container-fluid">
          <ul className="cards">
            {servicesData?.map((service, index) => (
              <li
                className="cards_item1-main"
                key={index}
                onClick={() => handleServiceClick(service?.link)}
              >
                <div className="card-service-main">
                  <div>
                    <img src={service?.image} alt="" className="img-service" />
                  </div>
                  <a href={service?.link}>
                    <h3 className="blog-se">{service?.title}</h3>
                  </a>
                  <div className="service-content">{service?.content}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  );
}


