import React from 'react'
import { NavLink } from "react-router-dom";
import mailweb from "../../../assets/images/home/mail-1.png";
import { useHeader } from '../hook';

export const NavigationBar = () => {
   const { navShow,handleNavBarClick } = useHeader();
  return (
    <React.Fragment>
      <nav className={navShow == true ? "navbar active" : "navbar"}>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/About-Us">About Us</NavLink>
        <NavLink to="/Our-Team">Our Team</NavLink>
        <NavLink to="/Services">Services</NavLink>
        <NavLink to="/Blogs">Blogs</NavLink>
        <NavLink to="/Careers">Careers</NavLink>
        <NavLink to="/Contact-Us">Contact Us</NavLink>
        <a className="btn-land" href="/tax-consultants-in-uae">
          <img alt="" src={mailweb} className="img-icons"></img> Quick Contact
        </a>
      </nav>
      <div id="menu-btn" onClick={handleNavBarClick}>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
    </React.Fragment>
  );
}
