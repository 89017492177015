import React from "react";
import UnleashBusines from "../../../assets/images/home/business-setup-in dubai-free-zone-accruon-consultants-llc.jpg";
import topTen from "../../../assets/images/home/top-ten-corporate-tax-consultants-in-uae-accruon-consultants-llc.jpg";
import BenefitsOfHiring from "../../../assets/images/home/benefits-of-hiring-tax-consultant-in-uae-accruon-consultants-llc.jpg";
import { useNavigate } from "react-router-dom";

function BlogStart() {
  const navigate = useNavigate();
  return (
    <>
      <section class="pt-5 circle-1 main">
        <h2 class="left h2-b">
          <span>Our Blogs</span>
        </h2>

        <h2 class="h-blog">Latest Blog & Articles</h2>
        <a href="/blogs">
          <h2 class="open-page">View All</h2>
        </a>
        <ul class="cards">
          <li
            class="cards_item"
            onClick={() =>
              navigate("/blogs/benefits-of-hiring-tax-consultant-in-uae")
            }
          >
            <div class="card">
              <div class="card_image">
                <img src={BenefitsOfHiring} alt="" class="img-blog" />
              </div>
              <h3 class="blog-h">
                Benefits of Hiring Tax Consultant in UAE : Maximize Savings &
                Minimize Risk
              </h3>
              <div class="card-content">
                Are you worried about tax complexities? Do you know the benefits
                of hiring tax consultants in UAE? Tax policies may create
                ambiguity but partnering with Accruon simplifies the process.
              </div>
              {/* <footer class="card-footer">
                <a
                  href="/blogs/benefits-of-hiring-tax-consultant-in-uae"
                  class="btn b-read"
                >
                  Read More
                </a>
              </footer> */}
            </div>
          </li>
          <li
            class="cards_item"
            onClick={() =>
              navigate("/blogs/top-ten-corporate-tax-consultants-in-uae")
            }
          >
            <div class="card">
              <div class="card_image">
                <img src={topTen} alt="" class="img-blog" />
              </div>
              <h3 class="blog-h">Top 10 Corporate Tax Consultants in UAE</h3>
              <div class="card-content">
                The Ministry of Finance has released guidelines to assist
                companies in registering for corporate tax and obtaining
                relevant information, about the implementation of Corporate tax
                in UAE.
              </div>
              {/* <footer class="card-footer">
                <a
                  href="/blogs/top-ten-corporate-tax-consultants-in-uae"
                  class="btn b-read"
                >
                  Read More
                </a>
              </footer> */}
            </div>
          </li>
          <li
            class="cards_item"
            onClick={() => navigate("/blogs/unleash-Business-Opportunities")}
          >
            <div class="card">
              <div class="card_image">
                <img src={UnleashBusines} alt="" class="img-blog" />
              </div>
              <h3 class="blog-h">
                Unleash Business Opportunities in UAE Free Zones
              </h3>
              <div class="card-content">
                Are you planning to set up a company/business in the free zone
                area in UAE? Do you need to know the tax exemptions and benefits
                of forming a company in a free zone in the UAE? We are here to
                provide you with the answer
              </div>
              {/* <footer class="card-footer">
                <a
                  href="/blogs/unleash-Business-Opportunities"
                  class="btn b-read"
                >
                  Read More
                </a>
              </footer> */}
            </div>
          </li>
        </ul>
      </section>
    </>
  );
}

export default BlogStart;
