import React from 'react'
import { useNavigate } from 'react-router-dom';

export const CardComponent = ({ imgsrc, CardHead, Content, path }) => {
    const navigate=useNavigate()
  const CardContent = () => {
    return (
      <li class="cards_item1" onClick={() => navigate(path)}>
        <div class="card-service">
          <div class="">
            <img src={imgsrc} class="img-service" alt="" />
          </div>
          <h3 class="blog-se">{CardHead}</h3>
          <div class="service-content">{Content}</div>
          <footer class="serve-footer">
            <a href={path} class="btn s-read">
              Learn More
            </a>
          </footer>
        </div>
      </li>
    );
  };
  return (
    <React.Fragment>
      <CardContent />
    </React.Fragment>
  );
};
