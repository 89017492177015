import React from "react";
import { UseScrollToTop } from "../../shared";
import PageHelmet from "../../shared/components/ReactHelmet";
import { NavPageHead } from "../../shared/components/NavPageHead";
import { CardComponent } from "../../shared/components/CardComponent";
import cfoIcon from "../../assets/images/services/virtual cfo.svg";
import Acquisitions from "../../assets/images/services/accussition and merge.svg";
import cmpnyformation from "../../assets/images/services/company formation.svg";
import coparate from "../../assets/images/services/coparate.webp";
import vaticon from "../../assets/images/services/vat-icon.webp";
import audit from "../../assets/images/services/audit.webp";
import icv from "../../assets/images/services/icv .webp";
import economic from "../../assets/images/services/economic.webp";
import business from "../../assets/images/services/business.webp";
import software from "../../assets/images/services/software.webp";
import excise from "../../assets/images/services/excise.webp";
import management from "../../assets/images/services/management.webp";
import investment from "../../assets/images/services/investment.webp";
import governance from "../../assets/images/services/governance.webp";
import hr from "../../assets/images/services/hr.webp";
import finance from "../../assets/images/services/finance.webp";
import registration from "../../assets/images/services/registration.webp";
import accounting from "../../assets/images/services/accounting.webp";

export const Services = () => {
    UseScrollToTop();
    const ServiceData = [
      {
        image: coparate,
        title: "Corporate Tax in UAE",
        content:
          "Corporate Tax in UAE is a type of direct tax applied on corporations or other businesses’ net revenue or profit. In most cases, such taxes are calculated using a company’s...",
        link: "/services/corporate-tax-in-uae",
      },
      {
        image: vaticon,
        title: "VAT Consultants in UAE",
        content:
          "Value Added Taxes are the taxes that are collected by the government from businesses directly or indirectly through other businesses....",
        link: "/services/vat-consultants-in-uae",
      },
      {
        image: audit,
        title: "Audit & Assurance",
        content:
          "One of the fundamental services that every organisation requires to keep track of their financial transactions is audit and assurance. Our Auditing service in Dubai..",
        link: "/services/audit-and-assurance-service-in-uae",
      },
      {
        image: icv,
        title: "National In-Country Value Program (ICV)",
        content:
          "The National In-Country Value (ICV) Certification Program, initiated by the UAE government, aims to enhance economic growth and bolster local industries by…",
        link: "/services/national-in-country-value-program",
      },
      {
        image: economic,
        title: "Economic Substance Regulations",
        content:
          "The Economic Substance Regulations (ESR) were introduced in the United Arab Emirates (UAE) via Cabinet Decision Number 31 of 2019, prompted by…",
        link: "/services/economic-substance-regulations",
      },
      {
        image: business,
        title: "Business Process Outsourcing",
        content:
          "We, Accruon Consultant, have decades of experience in the accounts and finance related activity outsourcing. Our expert team can handle…",
        link: "/services/business-process-outsourcing",
      },
      {
        image: software,
        title: "Software and ERP Consulting",
        content:
          "We provide solutions that are tailored to your company’s needs. Accruon Technologies provides customers with bespoke software development solutions.",
        link: "/services/software-and-ERP-consulting",
      },
      {
        image: excise,
        title: "Excise Tax Related Services",
        content:
          "The UAE government levies an indirect tax on commodities that are considered hazardous to human health or the environment. The…",
        link: "/services/excise-tax-related-services",
      },
      {
        image: management,
        title: "Management Consultancy Service",
        content:
          "We, Accruon Consultants, have vast experience in the field of Management Consulting. For us, management consulting is the..",
        link: "/services/management-consultancy-services",
      },
      {
        image: investment,
        title: "Investment Advisory – Invest in India",
        content:
          "The Government of India is actively establishing a network of industrial corridors nationwide, providing well-developed land and top-tier infrastructure for industrial townships…",
        link: "/services/investment-advisory-invest-in-india",
      },
      {
        image: governance,
        title: "Governance, Risk, Compliance (GRC)",
        content:
          "GRC service assists organizations to effectively monitor and manage risk and ensure compliance to regulatory requirements. It may so happen…",
        link: "/services/governance-risk-compliance",
      },
      {
        image: hr,
        title: "HR Advisory & Consulting",
        content:
          "Human Resources (HR) advisory and consulting services in the United Arab Emirates (UAE) are in demand due to the country's thriving business environment..",
        link: "/services/HR-advisory-and-consulting",
      },
      {
        image: finance,
        title: "Corporate Finance Consulting",
        content:
          "Corporate finance consulting in the UAE involves providing specialized financial advice and services to businesses in the region..",
        link: "/services/corporate-finance-consulting",
      },
      {
        image: registration,
        title: "Business Registration & Corporate Services",
        content:
          "Starting a business in the United Arab Emirates (UAE) can be made hassle-free by the support of Accruon Consultant. We…",
        link: "/services/business-registration-and-corporate-services",
      },
      {
        image: accounting,
        title: "Accounting & Bookkeeping",
        content:
          "Accounting and bookkeeping services in UAE, keep track of funds and are essential for any company, whether it is a…",
        link: "/services/accounting-and-bookkeeping-services",
      },
      {
        image: cmpnyformation,
        title: "Company Formation In UAE",
        content:
          "What makes UAE the perfect setting for entrepreneurs? Investors in the UAE are endowed with three different functional..",
        link: "/services/company-formation-in-uae",
      },
      {
        image: cfoIcon,
        title: "CFO Services",
        content:
          "A Chief Finance Officer(CFO) is an experienced finance expert who lends a hand in the smooth functioning of finance ...",
        link: "/services/cfo-services",
      },
      {
        image: Acquisitions,
        title: "Acquisitions & Mergers",
        content:
          "To conduct Mergers and Acquisitions process in a smooth manner, it is important to keep track of Joint Ventures and Sales and Purchase Agreements. ...",
        link: "/services/acquisitions-and-mergers",
      },
    ];

  return (
    <React.Fragment>
      <PageHelmet
        title={"Services | Accruon Consultant LLC"}
        description={""}
        canonical={"https://accruonconsultants.com/Services"}
      />
      <section className="main">
        <NavPageHead
          sideHead={"Services"}
          headClass={"h-blog-s"}
          headName={
            "Quality Services Helping Your Business Grow To Greater Heights."
          }
        />
        <ul class="cards">
          {ServiceData?.map((item,index) => (
              <CardComponent
                  key={index}
              CardHead={item?.title}
              imgsrc={item?.image}
              Content={item?.content}
              path={item?.link}
            />
          ))}
        </ul>
      </section>
    </React.Fragment>
  );
};
