
import NavBar from '../components/navbar'
import Footer from '../components/footer'
import axios from 'axios'
import React, { useEffect, useState ,Suspense } from 'react'


import { useNavigate,useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
const { REACT_APP_BASE_API_URL } = process.env
function CareersPage() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
    const [tableData, setTableData] = useState([]);
    const navigate = useNavigate();
    //Api call for session listing
    const getCareerList = () => {
        axios
            .get(`${REACT_APP_BASE_API_URL}/getAllCareers`)
            .then((response) => {
                console.log(response)
                if (response.data.message == "Success") {
                    setTableData(response.data.data)

                }
            })
    }
    useEffect(() => {
        getCareerList();
    }, [])

   
    console.log(tableData)
    return (
      <>
        <Helmet>
          <title>Careers| Accruon Consultant LLC</title>
          <meta name="description" content="" />
          <link rel="canonical" href="https://accruonconsultants.com/Careers" />
        </Helmet>
        {/* <NavBar></NavBar> */}
        <section className="main">
          <Suspense fallback={<Loading />}>
            <h1 class="left h1-b">
              <span>Careers</span>
            </h1>
            <h1 class="h-blog">Work with us</h1>
            <br />
            {tableData.length > 0 ? (
              tableData.map((item, index) => {
                return (
                  <>
                    <div class="container">
                      <div class="row">
                        <div class="col-md-10 offset-md-1">
                          <ul class="job-list">
                            <li class="job-preview">
                              <div class="content float-left">
                                <h4 class="job-title">{item.jobTitle}</h4>
                              </div>
                              <a
                                class="btn btn-apply float-sm-right float-xs-left"
                                onClick={() => {
                                  navigate(
                                    `/careers/${item._id}/careers-details`
                                  );

                                  // Add more items as needed
                                }}
                              >
                                More details
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <h1 class="h-blog">No Job Opennings</h1>
            )}
          </Suspense>
        </section>
        {/* <Footer></Footer> */}
      </>
    );
}

export default CareersPage
function Loading() {
    return <h2>🌀 Loading...</h2>;
  }