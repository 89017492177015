import React from 'react'
import Carousel from "react-multi-carousel";
export const CarouselComponent = ({
  children,
  settings,
  autoPlaySpeed,
  viewDots,
  autoPlay,
  responsive,
  contentStyle,
}) => {
  return (
    <Carousel
      autoPlay={autoPlay !== undefined ? autoPlay : true}
      // {...settings}
      // {...contentStyle}
      responsive={responsive ? responsive : {}}
      showDots={viewDots !== undefined ? viewDots : false}
      autoPlaySpeed={autoPlaySpeed !== undefined ? autoPlaySpeed : 3000}
      infinite
    >
      {children}
    </Carousel>
  );
};
