
import React from 'react'
import NavBar from '../../../components/navbar'
import Footer from '../../../components/footer'
import ContactSectionPages from '../../../components/contact'
import tax from '../../../../assets/images/services/Business-Process-Outsourcing.webp'
import { Helmet } from 'react-helmet'
function BusinessProcessOutsourcingPage() {
    return (
      <>
        <Helmet>
          <title>Business Process Outsourcing | Accruon Consultant LLC </title>
          <meta
            name="description"
            content="Our Business Process Outsourcing services help you handle end-to-end accounting outsourcing solutions which helps you to focus on core business activities."
          />
          <link
            rel="canonical"
            href="https://accruonconsultants.com/services/business-process-outsourcing"
          />
        </Helmet>
        {/* <NavBar></NavBar> */}
        <section class="about container  margin-top1">
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-12 md-pb">
              <div class="title-wrapper align-left">
                <h2 class="left h1-b">
                  <span>Service details</span>
                </h2>
                <h3 class="pb-3 about-h1">Business Process Outsourcing </h3>
                <p class="about-p1">
                  &nbsp;The business process outsourcing services are intended
                  to help businesses focus on core business activities. In the
                  BPO model, the support activities are outsourced to another
                  business.
                </p>
                <p class="about-p1">
                  We, Accruon Consultants, have decades of experience in
                  accounts and finance-related activity outsourcing. Our expert
                  team can handle end-to-end accounting outsourcing solutions
                  such as taking care of your bookkeeping, payroll, tax, and
                  regulatory activities even at the CFO level.
                </p>
                <p class="about-p1">
                  <mark>
                    &#x2713; Dedicated team with decades of experience
                  </mark>
                </p>
                <p class="about-p1">
                  <mark>&#x2713; Helps you to focus on your core business</mark>
                </p>
                <p class="about-p1">
                  <mark>
                    &#x2713; Makes regulatory compliences hassle-free for you
                  </mark>
                </p>
                <p class="about-p1">
                  <mark>&#x2713; Forecasting risks and ensuring growth</mark>
                </p>
                <p class="about-p1">
                  <mark>&#x2713; Always following industry best practices</mark>
                </p>
                <p class="about-p1">
                  <mark>&#x2713; All at affordable prices</mark>
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 md-pb">
              <img
                src={tax}
                class="mob-img"
                alt="Business Process Outsourcing"
              />
            </div>
          </div>
        </section>
        <ContactSectionPages />
        {/* <Footer></Footer> */}
      </>
    );
}

export default BusinessProcessOutsourcingPage