import React, { useEffect} from "react";
import mailbarkIcon from "../../../assets/images/home/email-new.webp";
import callbarkIcon from "../../../assets/images/home/ph-new.webp";
import whatupIcon from "../../../assets/images/home/whats-new.webp"
import bannerImage from "../../../assets/images/home/banner-mobile-imagenew.webp";
import "./index.css"
import { CarouselComponent } from "../../../shared/components/CarouselComponent";
function BannerSection() {
    useEffect(() => {
      const images = [mailbarkIcon, callbarkIcon, whatupIcon, bannerImage]; // Include the background image in the preloading process
      images.forEach((image) => {
        const img = new Image();
        img.src = image;
      });
    }, []);
    const settings = {
        dots: false,
        infinite: true,
        pauseOnHover: false
    };
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1,

        slidesToSlide: 1,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1200 },
        items: 1,
        slidesToSlide: 1,
      },

      tablet: {
        breakpoint: { max: 1300, min: 880 },
        items: 1,
        slidesToSlide: 1,
      },
      mobile: {
        breakpoint: { max: 880, min: 0 },
        items: 1,
      },
    };
    return (
      <>
        <div fetchpriority="high" class="main-v2">
          {/* <Carousel ref={slider => {
                        sliderRef = slider;
                    }}  autoplay {...settings}> */}
          <CarouselComponent
            autoPlay={true}
            infinite={true}
            responsive={responsive}
            settings={settings}
          >
            <div className="slide-parent slide-align">
              <div class="slide-content">
                <h2 class="slide-text slide-text--1">
                  <span>TRUSTED TAX CONSULTANTS FOR</span>
                </h2>
                <p class="slide-text slide-text--2">
                  <span className="main-h">
                    CORPORATE INCOME
                    <br />
                    TAX & VAT
                  </span>
                </p>
              </div>
            </div>
            <div className="slide-parent slide-align">
              <div class="slide-content">
                <h2 class="slide-text slide-text--1">
                  <span>A STRATEGIC PATH OF TRUE INVESTMENT</span>
                </h2>
                <p class="slide-text slide-text--2">
                  <span className="main-h">FINANCIAL ADVISOR</span>
                </p>
              </div>
            </div>
            <div className="slide-parent slide-align">
              <div class="slide-content">
                <h2 class="slide-text slide-text--1">
                  <span>ACCOUNTING OUTCOMES THAT YOU DESIRE</span>
                </h2>
                <p class="slide-text slide-text--2">
                  <span className="main-h">
                    ACCOUNTING MADE <br />
                    SIMPLE & SMART
                  </span>
                </p>
              </div>
            </div>
            <div className="slide-parent slide-align">
              <div class="slide-content">
                <h2 class="slide-text slide-text--1">
                  <span>EXPERT TAX CONSULTANTS IN UAE</span>
                </h2>
                <p class="slide-text slide-text--2">
                  <span className="main-h"> FOR FINANCIAL SUCCESS </span>
                </p>
              </div>
            </div>
          </CarouselComponent>
          {/* </Carousel > */}

          <div class="icon-bar">
            <a href="tel:+971 52913 7700" class="phone11" target="_blank">
              <img alt="" src={callbarkIcon} />
            </a>
            <a
              href="mailto:mail@accruonconsultants.com"
              class="email11"
              target="_blank"
            >
              <img alt="" src={mailbarkIcon} />
            </a>
            <a
              href="https://api.whatsapp.com/send/?phone=%2B971529137700&text&type=phone_number&app_absent=0"
              target="_blank"
              class="whats11"
            >
              <img alt="" src={whatupIcon} />
            </a>
          </div>
        </div>
      </>
    );
}

export default BannerSection