import { MainLayout } from "../layout";
import { Home, Services  } from "../pages";
import AboutUsPage from "./about-us";
import TopTenCorporateTaxConsultantInUae from "./blog-details/TopTenCorporateTaxConsultantInUae";
import BenefitsOfHiringTaxConsultantInUae from "./blog-details/BenefitsOfHiring";
import UnleashBusinessOpportunities from "./blog-details/unleash-Business-Opportunities";
import BlogPage from "./blogs";
import CareersPage from "./careers";
import ContactUsPage from "./contact-us";
import OurTeamsPage from "./our-teams";
// Blogs
import CorporateTaxinUAEPage from "./service/service-details/CorporateTaxinUAEPage/CorporateTaxinUAEPage";
import VATRelatedServicePage from "./service/service-details/VATRelatedService/VATRelatedService";
import AuditAssurancePage from "./service/service-details/AuditAssurance/AuditAssurance";
import NationalInCountryValueProgramPage from "./service/service-details/NationalIn-CountryValueProgram/NationalIn-CountryValueProgram";
import EconomicSubstanceRegulationsPage from "./service/service-details/EconomicSubstanceRegulations/EconomicSubstanceRegulations";
import BusinessProcessOutsourcingPage from "./service/service-details/BusinessProcessOutsourcing/BusinessProcessOutsourcing";
import SoftwareandERPConsultingPage from "./service/service-details/SoftwareandERPConsulting/SoftwareandERPConsulting";
import ExciseTaxRelatedServicesPage from "./service/service-details/ExciseTaxRelatedServices/ExciseTaxRelatedServices";
import ManagementConsultancyPage from "./service/service-details/ManagementConsultancy/ManagementConsultancy";
import InvestmentAdvisoryInvestinIndiaPage from "./service/service-details/InvestmentAdvisoryInvestinIndia/InvestmentAdvisoryInvestinIndia";
import GovernanceRiskCompliancePage from "./service/service-details/GovernanceRiskCompliance/GovernanceRiskCompliance";

import { lazy } from "react";
import HRAdvisoryConsultingPage from "./service/service-details/HRAdvisoryConsulting/HRAdvisoryConsulting";
import CorporateFinanceConsultingPage from "./service/service-details/CorporateFinanceConsulting/CorporateFinanceConsulting";
import BusinessRegistrationCorporateServicesPage from "./service/service-details/BusinessRegistrationCorporateServices/BusinessRegistrationCorporateServices";
import AccountingBookkeepingPage from "./service/service-details/AccountingBookkeeping/AccountingBookkeeping";
import CorporateTaxPenaltyofAEDforlateTaxRegistrationinUAE from "./blog-details/CorporateTaxPenaltyofAEDforlateTaxRegistrationinUAE.jsx";
const AmlAuditPage = lazy(() => import("./AuditAssurance/AMLAudit"));
const StatutoryAuditServicesPage = lazy(() =>
  import("./AuditAssurance/Statutoryauditservices")
);
const MysteryAuditPage = lazy(() =>
  import("./AuditAssurance/Mysteryaudit")
);
const ConcurrentAuditPage = lazy(() =>
  import("./AuditAssurance/Concurrentaudit")
);
const QuaterlyYearReportingPage = lazy(() =>
  import("./AuditAssurance/Quarterlyyearreporting")
);
const ForensicAuditPage = lazy(() =>
  import("./AuditAssurance/Forensicaudit")
);
const SalesCertificationPage = lazy(() =>
  import("./AuditAssurance/Salescertification")
);
const StockAuditPage = lazy(() => import("./AuditAssurance/Stockaudit"));
const TailoredAuditPage = lazy(() =>
  import("./AuditAssurance/Tailoredaudit")
); 
 
//bookkeeping &account
const AccountingServicePage = lazy(() => import('./bookaccount/accounting')); 
const BookKeepingPage = lazy(() => import('./bookaccount/bookkeeping')); 
const BackLogAccountPage = lazy(() => import('./bookaccount/backlogaccount')); 
const AccountSupervisionPage = lazy(() => import('./bookaccount/accountsupervision')); 
const AnalysisDesignAccount = lazy(() => import('./bookaccount/analysisdesignacc')); 
const CashFlowForecastingPage = lazy(() => import('./bookaccount/cashflowforecasting')); 
const PayrollOutSourcePage = lazy(() => import('./bookaccount/payrolloutsource')); 
const FixedAssestsPage = lazy(() => import('./bookaccount/fixedassests')); 
const InventoryVerificationPage = lazy(() => import('./bookaccount/inventoryverification')); 
const CorporateTaxPage = lazy(() => import('./bookaccount/corporatetax')); 

const PageNotFoundPage = lazy(() =>
  import("./pageNotFound/pageNotFound.jsx")
);
const DecodingUaeCoporateTaxPage = lazy(() =>
  import("./blog-details/decodinguaeCoporateTax.jsx")
);
const LandingHomePageConsultants = lazy(() =>
  import("./homepage-landing/index.jsx")
);
const ThankYouPage = lazy(() => import("./thankyou-page/index.jsx"));
const BlogDetailsPage = lazy(() => import("./blog-details"));
const GrowingBusinessPage = lazy(() =>
  import("./blog-details/growingBusiness")
);
const AmlPage = lazy(() => import("./blog-details/amlpage"));
const RoleAccountPage = lazy(() =>
  import("./blog-details/roleaccounting")
);
const DisClosureUaeBlog = lazy(() =>
  import("./blog-details/disclosureUae")
);
const CorporateTaxBlog = lazy(() =>
  import("./blog-details/coporatetax")
);

const ClaimVatBlog = lazy(() => import("./blog-details/claimVat"));
const BookKeepingBlog = lazy(() => import("./blog-details/bookKeeping"));

const AuditUaeBlog = lazy(() => import("./blog-details/auditUae"));
const CareersDetailPage = lazy(() => import("./careers/careersdetail"));

const ErpSystempage = lazy(() => import("./blog-details/ERPsystem")); 

//auditassurance
const VatAuditPage = lazy(() => import('./AuditAssurance/vataudit'));
const InternalFinancialAudit = lazy(() => import('./AuditAssurance/internalfinancialaudit'));  
const ExternalAuditPage = lazy(() => import('./AuditAssurance/externalaudit')); 
const CompanyLiquadationPage = lazy(() => import('./AuditAssurance/companyliquadation')); 
const ComplainAuditPage= lazy(() => import('./AuditAssurance/complainaudit')); 
const IFRSAdvisoryServicesPage = lazy(() => import('./AuditAssurance/IFRSAdvisoryServices')); 
const RiskManagementAuditPage= lazy(() => import('./AuditAssurance/RiskManagementAudit')); 
const FraudInvestigationPage = lazy(() => import('./AuditAssurance/FraudInvestigation')); 
const FreeZoneAuditPage = lazy(() => import('./AuditAssurance/FreeZoneAudit')); 
const DueDiligenceAuditPage = lazy(() => import('./AuditAssurance/DueDiligenceAUDIT')); 

// taxation in second navbar
const VATComplientBookkeepingPage = lazy(() => import('./taxationinsecondnavbar/VATComplientBookkeeping.jsx')); 
const VATconsultancyservicesinDubaiPage = lazy(() => import('./taxationinsecondnavbar/VATconsultancyservicesinDubai')); 
const ExciseTaxRelatedServicesPages = lazy(() => import('./taxationinsecondnavbar/ExciseTaxRelatedServices')); 
const RegulatoryComplianceServicesPage = lazy(() => import('./taxationinsecondnavbar/RegulatoryComplianceServices')); 
const TaxResidencyDomicileCertificatePage = lazy(() => import('./taxationinsecondnavbar/TaxResidency[Domicile]Certificate')); 
const VATReconsiderationinUAEPages = lazy(() => import('./taxationinsecondnavbar/VATReconsiderationinUAE')); 
const VATReturnFilinginUAEPages = lazy(() => import('./taxationinsecondnavbar/VATReturnFilinginUAE')); 
const VATRefundServicesinUAEPages = lazy(() => import('./taxationinsecondnavbar/VATRefundServicesinUAE')); 
const VATRegistrationinUAEPages = lazy(() => import('./taxationinsecondnavbar/VATRegistrationinUAE')); 
const VATDeregistrationServiceintheUAEPages = lazy(() => import('./taxationinsecondnavbar/VATDeregistrationServiceintheUAE')); 
const AdvisoryonDoubleTaxAvoidancePages = lazy(() => import('./taxationinsecondnavbar/AdvisoryonDoubleTaxAvoidance')); 
const VATHealthCheckServicesinDubaiUAEPages = lazy(() => import('./taxationinsecondnavbar/VATHealthCheckServicesinDubaiUAE')); 
const AssistanceofVATPenaltyWaiverPages = lazy(() => import('./taxationinsecondnavbar/AssistanceofVATPenaltyWaiver')); 
const UltimateBeneficiaryOwnerUBOCompliancePages = lazy(() => import('./taxationinsecondnavbar/UltimateBeneficiaryOwnerUBOCompliance')); 
const ExplanationandExclusionPages = lazy(() => import('./taxationinsecondnavbar/ExplanationandExclusion')); 
const VoluntaryDisclosurePages = lazy(() => import('./taxationinsecondnavbar/VoluntaryDisclosure')); 
const ESRFilingCompanyDubaiUnitedArabEmiratesPages = lazy(() => import('./taxationinsecondnavbar/ESRFilingCompanyDubai,UnitedArabEmirates')); 



// riskassurance
const FixedAssetsManagementPages = lazy(() => import('./riskassurance/FixedAssetsManagement')); 




// Acquisitions & Mergers
const ValuationofBusinessandAssetsPages = lazy(() => import('./Acquisitions&Mergers/ValuationofBusinessandAssets')); 
const AcquisitionsandMergersPages = lazy(() => import('./Acquisitions&Mergers/Acquisitions&Mergers')); 
const ReorganizationAndRestructuringPages = lazy(() => import('./Acquisitions&Mergers/ReorganizationAndRestructuring')); 



// Company Formations
const UAECompanyFormationPages = lazy(() => import('./CompanyFormations/UAECompanyFormation')); 
const OffshorePages = lazy(() => import('./CompanyFormations/Offshore')); 

// VirtualCFO
const VirtualCFOPages = lazy(() => import('./VirtualCFO')); 

//coporate Tax
const CoporateTaxConsultancy = lazy(()=>import('./coporatetax/coporatetaxconsultancy'));
const CoporateTaxComplainBooking = lazy(()=>import('./coporatetax/coptaxcomplainbook'));
const CoporateTaxRegistrationPage= lazy(()=>import('./coporatetax/coporatetaxregistration'));
const  CoporateTaxComplainAudit= lazy(()=>import('./coporatetax/complainaudit'));
const  CorporateTaxDocumentationPage= lazy(()=>import('./coporatetax/corporatetaxdocumentation'));
 const CorporateTaxUAEReturnFiling = lazy(()=>import('./coporatetax/uaereturningfiling'));
 const TransferPricingDocumentationunderCTLaw=lazy(()=>import('./coporatetax/transferpricingdocumentation'));
 const RelatedPartyTransactionPage=lazy(()=>import('./coporatetax/relatedpartytransaction'));
 const ConnectedPesonsCtLaw=lazy(()=>import("./coporatetax/connectedpersonsctlaw"));
 const TaxGroupinCorporateTaxLaw=lazy(()=>import("./coporatetax/taxgroupcorporate"));

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    { path: "/", element: <Home /> },
    { path: "/Services", element: <Services /> },
    { path: "/About-Us", element: <AboutUsPage /> },
    { path: "/Blogs", element: <BlogPage /> },
    { path: "/Our-Team", element: <OurTeamsPage />, showSearch: true },
    { path: "/Careers", element: <CareersPage />, showSearch: true },
    { path: "/Contact-Us", element: <ContactUsPage />, showSearch: true },
    { path: "/Blog-Details", element: <BlogDetailsPage />, showSearch: true },
    {
      path: "careers/:careerId/careers-details",
      element: <CareersDetailPage />,
      showSearch: true,
    },
    {
      path: "/blogs/growing-a-business-in-the-uae",
      element: <GrowingBusinessPage />,
      showSearch: true,
    },
    {
      path: "/blogs/anti-money-laundering-laws-aml-in-uae",
      element: <AmlPage />,
      showSearch: true,
    },
    {
      path: "/blogs/role-of-accounting-in-it-business",
      element: <RoleAccountPage />,
      showSearch: true,
    },
    {
      path: "/blogs/all-you-need-to-know-about-uae-corporate-tax",
      element: <CorporateTaxBlog />,
      showSearch: true,
    },
    {
      path: "/blogs/importance-of-voluntary-disclosure-in-uae",
      element: <DisClosureUaeBlog />,
      showSearch: true,
    },
    {
      path: "/blogs/how-to-claim-vat-refund-in-uae",
      element: <ClaimVatBlog />,
      showSearch: true,
    },
    {
      path: "/blogs/purpose-of-book-keeping-in-united-arab-emirates",
      element: <BookKeepingBlog />,
      showSearch: true,
    },
    {
      path: "/blogs/why-auditing-is-important-in-uae",
      element: <AuditUaeBlog />,
      showSearch: true,
    },
    {
      path: "/blogs/erp-software-system-in-uae",
      element: <ErpSystempage />,
      showSearch: true,
    },
    {
      path: "/blogs/decoding-uae-corporate-tax-law",
      element: <DecodingUaeCoporateTaxPage />,
      showSearch: true,
    },
    {
      path: "/blogs/unleash-Business-Opportunities",
      element: <UnleashBusinessOpportunities />,
      showSearch: true,
    },
    {
      path: "/blogs/top-ten-corporate-tax-consultants-in-uae",
      element: <TopTenCorporateTaxConsultantInUae />,
      showSearch: true,
    },
    {
      path: "/blogs/benefits-of-hiring-tax-consultant-in-uae",
      element: <BenefitsOfHiringTaxConsultantInUae />,
      showSearch: true,
    },
    {
      path: "/blogs/corporate-tax-penalty-of-aed-ten-thousand-for-late-tax-registration-in-uae",
      element: <CorporateTaxPenaltyofAEDforlateTaxRegistrationinUAE />,
      showSearch: true,
    },
    {
      path: "/services/audit-assurance/vat-audit",
      element: <VatAuditPage />,
      showSearch: true,
    },
    {
      path: "/services/risk-assurance-2/internal-audit",
      element: <InternalFinancialAudit />,
      showSearch: true,
    },
    {
      path: "/services/audit-assurance/external-audit",
      element: <ExternalAuditPage />,
      showSearch: true,
    },
    {
      path: "/services/audit-assurance/company-liquidation",
      element: <CompanyLiquadationPage />,
      showSearch: true,
    },
    {
      path: "/services/compliance-audit",
      element: <ComplainAuditPage />,
      showSearch: true,
    },
    {
      path: "/services/ifrs-advisory-services",
      element: <IFRSAdvisoryServicesPage />,
      showSearch: true,
    },
    {
      path: "/services/risk-management-audit",
      element: <RiskManagementAuditPage />,
      showSearch: true,
    },
    {
      path: "/services/audit-assurance/fraud-investigation",
      element: <FraudInvestigationPage />,
      showSearch: true,
    },
    {
      path: "/services/audit-assurance/free-zone-audit",
      element: <FreeZoneAuditPage />,
      showSearch: true,
    },
    {
      path: "/services/audit-assurance/financial-tax-and-legal-due-diligence",
      element: <DueDiligenceAuditPage />,
      showSearch: true,
    },
    {
      path: "/services/audit-assurance/anti-money-laundering-aml-audit/-laundering-aml-audit",
      element: <AmlAuditPage />,
    },
    {
      path: "/services/audit-assurance/statutory-audit-services",
      element: <StatutoryAuditServicesPage />,
    },
    {
      path: "/services/audit-assurance/mystery-audit",
      element: <MysteryAuditPage />,
    },
    { path: "/concurrent-audit", element: <ConcurrentAuditPage /> },
    {
      path: "/services/audit-assurance/quarterly-year-reporting",
      element: <QuaterlyYearReportingPage />,
    },
    {
      path: "/services/audit-assurance/forensic-audit",
      element: <ForensicAuditPage />,
    },
    {
      path: "/services/audit-assurance/sales-certification",
      element: <SalesCertificationPage />,
    },
    {
      path: "/services/audit-assurance/stock-audit-verification",
      element: <StockAuditPage />,
    },
    {
      path: "/services/audit-assurance/tailored-audit",
      element: <TailoredAuditPage />,
    },
    {
      path: "/services/audit-assurance/accounting-services-in-uae",
      element: <AccountingServicePage />,
    },
    {
      path: "/services/accounting-and-bookkeeping-services/bookkeeping-service-uae",
      element: <BookKeepingPage />,
    },
    {
      path: "/services/accounting-and-bookkeeping-services/backlog-accounting-service-uae",
      element: <BackLogAccountPage />,
    },
    {
      path: "/accounting-supervision-services",
      element: <AccountSupervisionPage />,
    },
    {
      path: "/services/accounting-and-bookkeeping-services/analysis-and-design",
      element: <AnalysisDesignAccount />,
    },
    {
      path: "/cash-flow-and-forecasting-services",
      element: <CashFlowForecastingPage />,
    },
    { path: "/payroll-outsourcing-in-uae", element: <PayrollOutSourcePage /> },
    {
      path: "/services/risk-assurance-2/fixed-assets-management",
      element: <FixedAssestsPage />,
    },
    {
      path: "/services/inventory-verification",
      element: <InventoryVerificationPage />,
    },
    {
      path: "/services/vat-consultants-uae/corporate-tax-consultant-related-services",
      element: <CorporateTaxPage />,
    },
    {
      path: "/services/corporate-tax-in-uae",
      element: <CorporateTaxinUAEPage />,
    },
    {
      path: "/services/vat-consultants-in-uae",
      element: <VATRelatedServicePage />,
    },
    {
      path: "/services/audit-and-assurance-service-in-uae",
      element: <AuditAssurancePage />,
    },
    {
      path: "/services/national-in-country-value-program",
      element: <NationalInCountryValueProgramPage />,
    },
    {
      path: "/services/economic-substance-regulations",
      element: <EconomicSubstanceRegulationsPage />,
    },
    {
      path: "/services/business-process-outsourcing",
      element: <BusinessProcessOutsourcingPage />,
    },
    {
      path: "/services/software-and-ERP-consulting",
      element: <SoftwareandERPConsultingPage />,
    },
    {
      path: "/services/excise-tax-related-services",
      element: <ExciseTaxRelatedServicesPage />,
    },
    {
      path: "/services/management-consultancy-services",
      element: <ManagementConsultancyPage />,
    },
    {
      path: "/services/investment-advisory-invest-in-india",
      element: <InvestmentAdvisoryInvestinIndiaPage />,
    },
    {
      path: "/services/governance-risk-compliance",
      element: <GovernanceRiskCompliancePage />,
    },
    {
      path: "/services/HR-advisory-and-consulting",
      element: <HRAdvisoryConsultingPage />,
    },
    {
      path: "/services/corporate-finance-consulting",
      element: <CorporateFinanceConsultingPage />,
    },
    {
      path: "/services/business-registration-and-corporate-services",
      element: <BusinessRegistrationCorporateServicesPage />,
    },
    {
      path: "/services/accounting-and-bookkeeping-services",
      element: <AccountingBookkeepingPage />,
    },
    {
      path: "/services/vat-consultants-uae/vat-complient-bookkeeping",
      element: <VATComplientBookkeepingPage />,
    },
    {
      path: "/vat-consultancy-services",
      element: <VATconsultancyservicesinDubaiPage />,
    },
    {
      path: "/services/excise-tax-related-services",
      element: <ExciseTaxRelatedServicesPages />,
    },
    {
      path: "/services/regulatory-compliance-services",
      element: <RegulatoryComplianceServicesPage />,
    },
    {
      path: "/services/audit-assurance/tax-residency-domicile-certificate",
      element: <TaxResidencyDomicileCertificatePage />,
    },
    {
      path: "/services/vat-consultants-uae/vat-reconsideration",
      element: <VATReconsiderationinUAEPages />,
    },
    {
      path: "/services/vat-consultants-uae/vat-return-filing",
      element: <VATReturnFilinginUAEPages />,
    },
    {
      path: "/services/vat-consultants-uae/vat-refund-service",
      element: <VATRefundServicesinUAEPages />,
    },
    {
      path: "/services/vat-consultants-uae/vat-registration",
      element: <VATRegistrationinUAEPages />,
    },
    {
      path: "/services/vat-consultants-uae/vat-de-registration",
      element: <VATDeregistrationServiceintheUAEPages />,
    },
    {
      path: "/services/audit-assurance/advisory-on-double-tax-avoidance",
      element: <AdvisoryonDoubleTaxAvoidancePages />,
    },
    {
      path: "/services/audit-assurance/vat-health-check-services-in-dubai-uae",
      element: <VATHealthCheckServicesinDubaiUAEPages />,
    },
    {
      path: "/services/audit-assurance/assistance-of-vat-penalty-waiver",
      element: <AssistanceofVATPenaltyWaiverPages />,
    },
    {
      path: "/services/audit-assurance/ultimate-beneficiary-owner-ubo-compliance",
      element: <UltimateBeneficiaryOwnerUBOCompliancePages />,
    },
    {
      path: "/services/audit-assurance/explanation-and-exclusion",
      element: <ExplanationandExclusionPages />,
    },
    {
      path: "/services/audit-assurance/voluntary-disclosure",
      element: <VoluntaryDisclosurePages />,
    },
    {
      path: "/services/audit-assurance/esr-filing-company-dubai-united-arab-emirates",
      element: <ESRFilingCompanyDubaiUnitedArabEmiratesPages />,
    },
    {
      path: "/Fixed-Assets-Management-Pages",
      element: <FixedAssetsManagementPages />,
    },
    {
      path: "/services/audit-assurance/valuation-of-business-and-assets",
      element: <ValuationofBusinessandAssetsPages />,
    },
    {
      path: "/services/acquisitions-and-mergers",
      element: <AcquisitionsandMergersPages />,
    },
    {
      path: "/services/audit-assurance/reorganization-and-restructuring",
      element: <ReorganizationAndRestructuringPages />,
    },
    {
      path: "/services/company-formation-in-uae",
      element: <UAECompanyFormationPages />,
    },
    { path: "/services/audit-assurance/offshore", element: <OffshorePages /> },
    { path: "/services/cfo-services", element: <VirtualCFOPages /> },
    { path: "/Corporate-Tax-Consultancy", element: <CoporateTaxConsultancy /> },
    {
      path: "/Corporate-Tax-ComplainBook",
      element: <CoporateTaxComplainBooking />,
    },
    {
      path: "/Corporate-tax-registration",
      element: <CoporateTaxRegistrationPage />,
    },
    {
      path: "/Corporate-tax-Compliance-Audit",
      element: <CoporateTaxComplainAudit />,
    },
    {
      path: "/Corporate-tax-documentation",
      element: <CorporateTaxDocumentationPage />,
    },
    {
      path: "/Corporate-tax-UAE-Return-Filing",
      element: <CorporateTaxUAEReturnFiling />,
    },
    {
      path: "/Transfer-Pricing-Documentation",
      element: <TransferPricingDocumentationunderCTLaw />,
    },
    {
      path: "/Related-Party-TransactionPage",
      element: <RelatedPartyTransactionPage />,
    },
    { path: "/Connected-Persons-in-CT-Law", element: <ConnectedPesonsCtLaw /> },
    {
      path: "/Tax-Group-in-Corporate-Tax-Law",
      element: <TaxGroupinCorporateTaxLaw />,
    },
    {
      path: "/tax-consultants-in-uae",
      element: <LandingHomePageConsultants />,
    },
    { path: "/Thankyou", element: <ThankYouPage /> },
    { path: "*", element: <PageNotFoundPage /> },
  ],
};
export default MainRoutes;