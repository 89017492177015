import React, { useRef} from "react";
import { Carousel } from "antd";
import karima from "../../../assets/images/home/karima.webp";
import filtrec from "../../../assets/images/home/filtrec.webp";
import visionexports from "../../../assets/images/home/vision-exports.webp";
import cornation from "../../../assets/images/home/cornation.webp";
import cleantel from "../../../assets/images/home/clean-tel.webp";
import autoplus from "../../../assets/images/home/autoplus.webp";
import steelmart from "../../../assets/images/home/steel-mart.webp";
import lattafa from "../../../assets/images/home/lattafa.webp";
import csc from "../../../assets/images/home/csc.webp";
import albarakahdates from "../../../assets/images/home/al-barakah-dates.webp";
import starfluid from "../../../assets/images/home/star-fluid.webp";
import rawahi from "../../../assets/images/home/rawahi.webp";
import op3 from "../../../assets/images/home/op-3.webp";

import "../index.css";
import { NavLink } from "react-router-dom";
import { CarouselComponent } from "../../../shared/components/CarouselComponent";
function TopClients() {
  //design

  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplaySpeed: 1400,
    // cssEase: "linear",
    pauseOnHover: false,
  };
  const contentStyle = {
    height: "583px",
    color: "#fff",
    lineHeight: "400px",
    textAlign: "left",
    background: "#364d79",
    padding: "200px",
  };
   const responsive = {
     superLargeDesktop: {
       breakpoint: { max: 4000, min: 3000 },
       items: 5,

       slidesToSlide: 1,
     },
     desktop: {
       breakpoint: { max: 3000, min: 1200 },
       items: 5,
       slidesToSlide: 1,
     },

     tablet: {
       breakpoint: { max: 1300, min: 880 },
       items: 5,
       slidesToSlide: 1,
     },
     mobile: {
       breakpoint: { max: 880, min: 0 },
       items: 5,
     },
   };
  return (
    <>
      <div class="bg-c">
        <div class="main">
          <h2 class="left h2-b h2-b1 left-heading h2-b1">
            <span>Our Top Clients</span>
          </h2>
          <div class="main-feature"></div>

          <h2 class="h-blog">Featured Clients</h2>
          <br />
          <div className="main" id="main-f">
            {/* <Carousel
              ref={(slider) => {
                sliderRef = slider;
              }}
              autoplay
              {...settings}
              {...contentStyle}
            > */}
            <CarouselComponent
              autoPlay={true}
              responsive={responsive ? responsive : {}}
              autoplaySpeed={1400}
            >
              <img src={karima} alt="" className="topclientsimage" />
              <NavLink to="https://www.filtrec.com/" target="_blank">
                <img src={filtrec} alt="" />
              </NavLink>
              <img src={visionexports} alt="" />
              <NavLink
                to="https://www.coronation.com/en/institutional/"
                target="_blank"
              >
                <img src={cornation} alt="" />
              </NavLink>
              <NavLink to="https://cleantel.me/" target="_blank">
                {" "}
                <img src={cleantel} alt="" />
              </NavLink>
              <img src={autoplus} alt="" />
              <img src={steelmart} alt="" />
              <img src={lattafa} alt="" />
              <NavLink to="https://www.confidenceshipping.com/">
                <img src={csc} alt="" />
              </NavLink>
              <NavLink to="https://albarakahdatesfactory.com/" target="_blank">
                <img src={albarakahdates} alt="" />
              </NavLink>
              <NavLink to="https://stardf.com/index.html" target="_blank">
                <img src={starfluid} alt="" />
              </NavLink>
              <img src={rawahi} alt="" />
              <NavLink to="https://op3global.com/" target="_blank">
                {" "}
                <img src={op3} alt="" />
              </NavLink>
            </CarouselComponent>
            {/* </Carousel> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default TopClients;
