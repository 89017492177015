import "./App.css";
import { Route, Routes } from "react-router-dom";
import React, { lazy } from "react";
import ReactGA from "react-ga";
import OurServicesPage from "./routes/service";
import AccruonHomePAge from "./routes/homepage";
import AboutUsPage from "./routes/about-us";
import BlogPage from "./routes/blogs";
import OurTeamsPage from "./routes/our-teams";
import CareersPage from "./routes/careers";
import ContactUsPage from "./routes/contact-us";
// blogss
import BenefitsOfHiringTaxConsultantInUae from "./routes/blog-details/BenefitsOfHiring.jsx";
import UnleashBusinessOpportunities from "./routes/blog-details/unleash-Business-Opportunities.jsx";
import TopTenCorporateTaxConsultantInUae from "./routes/blog-details/TopTenCorporateTaxConsultantInUae.jsx";
//google analitics
import CorporateTaxinUAEPage from "./routes/service/service-details/CorporateTaxinUAEPage/CorporateTaxinUAEPage";
import VATRelatedServicePage from "./routes/service/service-details/VATRelatedService/VATRelatedService";
import AuditAssurancePage from "./routes/service/service-details/AuditAssurance/AuditAssurance";
import NationalInCountryValueProgramPage from "./routes/service/service-details/NationalIn-CountryValueProgram/NationalIn-CountryValueProgram";
import EconomicSubstanceRegulationsPage from "./routes/service/service-details/EconomicSubstanceRegulations/EconomicSubstanceRegulations";
import BusinessProcessOutsourcingPage from "./routes/service/service-details/BusinessProcessOutsourcing/BusinessProcessOutsourcing";
import SoftwareandERPConsultingPage from "./routes/service/service-details/SoftwareandERPConsulting/SoftwareandERPConsulting";
import ExciseTaxRelatedServicesPage from "./routes/service/service-details/ExciseTaxRelatedServices/ExciseTaxRelatedServices";
import ManagementConsultancyPage from "./routes/service/service-details/ManagementConsultancy/ManagementConsultancy";
import InvestmentAdvisoryInvestinIndiaPage from "./routes/service/service-details/InvestmentAdvisoryInvestinIndia/InvestmentAdvisoryInvestinIndia";
import GovernanceRiskCompliancePage from "./routes/service/service-details/GovernanceRiskCompliance/GovernanceRiskCompliance";
import HRAdvisoryConsultingPage from "./routes/service/service-details/HRAdvisoryConsulting/HRAdvisoryConsulting";
import CorporateFinanceConsultingPage from "./routes/service/service-details/CorporateFinanceConsulting/CorporateFinanceConsulting";
import BusinessRegistrationCorporateServicesPage from "./routes/service/service-details/BusinessRegistrationCorporateServices/BusinessRegistrationCorporateServices";
import AccountingBookkeepingPage from "./routes/service/service-details/AccountingBookkeeping/AccountingBookkeeping";
import { MainLayout } from "./layout/index.jsx";

//blog
// home section
// const AccruonHomePAge = Suspense(lazy(() => import("./routes/homepage")));
// const OurServicesPage = Suspense(lazy(() => import("./routes/service")));
// const AboutUsPage = Suspense(lazy(() => import("./routes/about-us")));
// const BlogPage = lazy(() => import("./routes/blogs"));
// const OurTeamsPage = lazy(() => import("./routes/our-teams"));
// const CareersPage = lazy(() => import("./routes/careers"));
// const ContactUsPage = lazy(() => import("./routes/contact-us"));
//blog
const PageNotFoundPage = lazy(() =>
  import("./routes/pageNotFound/pageNotFound.jsx")
);
const DecodingUaeCoporateTaxPage = lazy(() =>
  import("./routes/blog-details/decodinguaeCoporateTax.jsx")
);
// const UnleashBusinessOpportunities = lazy(() =>
//   import("./routes/blog-details/unleash-Business-Opportunities.jsx")
// );
// const TopTenCorporateTaxConsultantInUae = lazy(() =>
//   import("./routes/blog-details/TopTenCorporateTaxConsultantInUae.jsx")
// );
const LandingHomePageConsultants = lazy(() =>
  import("./routes/homepage-landing/index.jsx")
);
const ThankYouPage = lazy(() => import("./routes/thankyou-page/index.jsx"));
const BlogDetailsPage = lazy(() => import("./routes/blog-details"));
const GrowingBusinessPage = lazy(() =>
  import("./routes/blog-details/growingBusiness")
);
const AmlPage = lazy(() => import("./routes/blog-details/amlpage"));
const RoleAccountPage = lazy(() =>
  import("./routes/blog-details/roleaccounting")
);
const DisClosureUaeBlog = lazy(() =>
  import("./routes/blog-details/disclosureUae")
);
const CorporateTaxBlog = lazy(() =>
  import("./routes/blog-details/coporatetax")
);

const ClaimVatBlog = lazy(() => import("./routes/blog-details/claimVat"));
const BookKeepingBlog = lazy(() => import("./routes/blog-details/bookKeeping"));

const AuditUaeBlog = lazy(() => import("./routes/blog-details/auditUae"));
const CareersDetailPage = lazy(() => import("./routes/careers/careersdetail"));

const ErpSystempage = lazy(() => import("./routes/blog-details/ERPsystem"));

// services
/// const VATRelatedServicePage = lazy(() => import('./routes/service/service-details/VATRelatedService/VATRelatedService'));
// const AuditAssurancePage = lazy(() => import('./routes/service/service-details/AuditAssurance/AuditAssurance'));
// const NationalInCountryValueProgramPage = lazy(() => import('./routes/service/service-details/NationalIn-CountryValueProgram/NationalIn-CountryValueProgram'));
// const EconomicSubstanceRegulationsPage = lazy(() => import('./routes/service/service-details/EconomicSubstanceRegulations/EconomicSubstanceRegulations'));
// const BusinessProcessOutsourcingPage = lazy(() => import('./routes/service/service-details/BusinessProcessOutsourcing/BusinessProcessOutsourcing'));
// const SoftwareandERPConsultingPage = lazy(() => import('./routes/service/service-details/SoftwareandERPConsulting/SoftwareandERPConsulting'));
// const ExciseTaxRelatedServicesPage = lazy(() => import('./routes/service/service-details/ExciseTaxRelatedServices/ExciseTaxRelatedServices'));
// const ManagementConsultancyPage = lazy(() => import('./routes/service/service-details/ManagementConsultancy/ManagementConsultancy'));
// const InvestmentAdvisoryInvestinIndiaPage = lazy(() => import('./routes/service/service-details/InvestmentAdvisoryInvestinIndia/InvestmentAdvisoryInvestinIndia'));
// const GovernanceRiskCompliancePage = lazy(() => import('./routes/service/service-details/GovernanceRiskCompliance/GovernanceRiskCompliance'));
// const HRAdvisoryConsultingPage = lazy(() => import('./routes/service/service-details/HRAdvisoryConsulting/HRAdvisoryConsulting'));
// const CorporateFinanceConsultingPage = lazy(() => import('./routes/service/service-details/CorporateFinanceConsulting/CorporateFinanceConsulting'));
// const BusinessRegistrationCorporateServicesPage = lazy(() => import('./routes/service/service-details/BusinessRegistrationCorporateServices/BusinessRegistrationCorporateServices'));
// const AccountingBookkeepingPage = lazy(() => import('./routes/service/service-details/AccountingBookkeeping/AccountingBookkeeping'));

//auditassurance
const VatAuditPage = lazy(() => import("./routes/AuditAssurance/vataudit"));
const InternalFinancialAudit = lazy(() =>
  import("./routes/AuditAssurance/internalfinancialaudit")
);
const ExternalAuditPage = lazy(() =>
  import("./routes/AuditAssurance/externalaudit")
);
const CompanyLiquadationPage = lazy(() =>
  import("./routes/AuditAssurance/companyliquadation")
);
const ComplainAuditPage = lazy(() =>
  import("./routes/AuditAssurance/complainaudit")
);
const IFRSAdvisoryServicesPage = lazy(() =>
  import("./routes/AuditAssurance/IFRSAdvisoryServices")
);
const RiskManagementAuditPage = lazy(() =>
  import("./routes/AuditAssurance/RiskManagementAudit")
);
const FraudInvestigationPage = lazy(() =>
  import("./routes/AuditAssurance/FraudInvestigation")
);
const FreeZoneAuditPage = lazy(() =>
  import("./routes/AuditAssurance/FreeZoneAudit")
);
const DueDiligenceAuditPage = lazy(() =>
  import("./routes/AuditAssurance/DueDiligenceAUDIT")
);
const AmlAuditPage = lazy(() => import("./routes/AuditAssurance/AMLAudit"));
const StatutoryAuditServicesPage = lazy(() =>
  import("./routes/AuditAssurance/Statutoryauditservices")
);
const MysteryAuditPage = lazy(() =>
  import("./routes/AuditAssurance/Mysteryaudit")
);
const ConcurrentAuditPage = lazy(() =>
  import("./routes/AuditAssurance/Concurrentaudit")
);
const QuaterlyYearReportingPage = lazy(() =>
  import("./routes/AuditAssurance/Quarterlyyearreporting")
);
const ForensicAuditPage = lazy(() =>
  import("./routes/AuditAssurance/Forensicaudit")
);
const SalesCertificationPage = lazy(() =>
  import("./routes/AuditAssurance/Salescertification")
);
const StockAuditPage = lazy(() => import("./routes/AuditAssurance/Stockaudit"));
const TailoredAuditPage = lazy(() =>
  import("./routes/AuditAssurance/Tailoredaudit")
);

// taxation in second navbar
const VATComplientBookkeepingPage = lazy(() =>
  import("./routes/taxationinsecondnavbar/VATComplientBookkeeping.jsx")
);
const VATconsultancyservicesinDubaiPage = lazy(() =>
  import("./routes/taxationinsecondnavbar/VATconsultancyservicesinDubai")
);
const ExciseTaxRelatedServicesPages = lazy(() =>
  import("./routes/taxationinsecondnavbar/ExciseTaxRelatedServices")
);
const RegulatoryComplianceServicesPage = lazy(() =>
  import("./routes/taxationinsecondnavbar/RegulatoryComplianceServices")
);
const TaxResidencyDomicileCertificatePage = lazy(() =>
  import("./routes/taxationinsecondnavbar/TaxResidency[Domicile]Certificate")
);
const VATReconsiderationinUAEPages = lazy(() =>
  import("./routes/taxationinsecondnavbar/VATReconsiderationinUAE")
);
const VATReturnFilinginUAEPages = lazy(() =>
  import("./routes/taxationinsecondnavbar/VATReturnFilinginUAE")
);
const VATRefundServicesinUAEPages = lazy(() =>
  import("./routes/taxationinsecondnavbar/VATRefundServicesinUAE")
);
const VATRegistrationinUAEPages = lazy(() =>
  import("./routes/taxationinsecondnavbar/VATRegistrationinUAE")
);
const VATDeregistrationServiceintheUAEPages = lazy(() =>
  import("./routes/taxationinsecondnavbar/VATDeregistrationServiceintheUAE")
);
const AdvisoryonDoubleTaxAvoidancePages = lazy(() =>
  import("./routes/taxationinsecondnavbar/AdvisoryonDoubleTaxAvoidance")
);
const VATHealthCheckServicesinDubaiUAEPages = lazy(() =>
  import("./routes/taxationinsecondnavbar/VATHealthCheckServicesinDubaiUAE")
);
const AssistanceofVATPenaltyWaiverPages = lazy(() =>
  import("./routes/taxationinsecondnavbar/AssistanceofVATPenaltyWaiver")
);
const UltimateBeneficiaryOwnerUBOCompliancePages = lazy(() =>
  import(
    "./routes/taxationinsecondnavbar/UltimateBeneficiaryOwnerUBOCompliance"
  )
);
const ExplanationandExclusionPages = lazy(() =>
  import("./routes/taxationinsecondnavbar/ExplanationandExclusion")
);
const VoluntaryDisclosurePages = lazy(() =>
  import("./routes/taxationinsecondnavbar/VoluntaryDisclosure")
);
const ESRFilingCompanyDubaiUnitedArabEmiratesPages = lazy(() =>
  import(
    "./routes/taxationinsecondnavbar/ESRFilingCompanyDubai,UnitedArabEmirates"
  )
);

//bookkeeping &account
const AccountingServicePage = lazy(() =>
  import("./routes/bookaccount/accounting")
);
const BookKeepingPage = lazy(() => import("./routes/bookaccount/bookkeeping"));
const BackLogAccountPage = lazy(() =>
  import("./routes/bookaccount/backlogaccount")
);
const AccountSupervisionPage = lazy(() =>
  import("./routes/bookaccount/accountsupervision")
);
const AnalysisDesignAccount = lazy(() =>
  import("./routes/bookaccount/analysisdesignacc")
);
const CashFlowForecastingPage = lazy(() =>
  import("./routes/bookaccount/cashflowforecasting")
);
const PayrollOutSourcePage = lazy(() =>
  import("./routes/bookaccount/payrolloutsource")
);
const FixedAssestsPage = lazy(() =>
  import("./routes/bookaccount/fixedassests")
);
const InventoryVerificationPage = lazy(() =>
  import("./routes/bookaccount/inventoryverification")
);
const CorporateTaxPage = lazy(() =>
  import("./routes/bookaccount/corporatetax")
);

// riskassurance
const FixedAssetsManagementPages = lazy(() =>
  import("./routes/riskassurance/FixedAssetsManagement")
);

// Acquisitions & Mergers
const ValuationofBusinessandAssetsPages = lazy(() =>
  import("./routes/Acquisitions&Mergers/ValuationofBusinessandAssets")
);
const AcquisitionsandMergersPages = lazy(() =>
  import("./routes/Acquisitions&Mergers/Acquisitions&Mergers")
);
const ReorganizationAndRestructuringPages = lazy(() =>
  import("./routes/Acquisitions&Mergers/ReorganizationAndRestructuring")
);

// Company Formations
const UAECompanyFormationPages = lazy(() =>
  import("./routes/CompanyFormations/UAECompanyFormation")
);
const OffshorePages = lazy(() => import("./routes/CompanyFormations/Offshore"));

// VirtualCFO
const VirtualCFOPages = lazy(() => import("./routes/VirtualCFO"));

//coporate Tax
const CoporateTaxConsultancy = lazy(() =>
  import("./routes/coporatetax/coporatetaxconsultancy")
);
const CoporateTaxComplainBooking = lazy(() =>
  import("./routes/coporatetax/coptaxcomplainbook")
);
const CoporateTaxRegistrationPage = lazy(() =>
  import("./routes/coporatetax/coporatetaxregistration")
);
const CoporateTaxComplainAudit = lazy(() =>
  import("./routes/coporatetax/complainaudit")
);
const CorporateTaxDocumentationPage = lazy(() =>
  import("./routes/coporatetax/corporatetaxdocumentation")
);
const CorporateTaxUAEReturnFiling = lazy(() =>
  import("./routes/coporatetax/uaereturningfiling")
);
const TransferPricingDocumentationunderCTLaw = lazy(() =>
  import("./routes/coporatetax/transferpricingdocumentation")
);
const RelatedPartyTransactionPage = lazy(() =>
  import("./routes/coporatetax/relatedpartytransaction")
);
const ConnectedPesonsCtLaw = lazy(() =>
  import("./routes/coporatetax/connectedpersonsctlaw")
);
const TaxGroupinCorporateTaxLaw = lazy(() =>
  import("./routes/coporatetax/taxgroupcorporate")
);
// const Tracking_ID="UA-241870810-1"; //OUR_TRACKINGID
// ReactGA.initialize(Tracking_ID);

const TRACKING_ID = "GTM-P2F4BQ"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <>
      <Routes>
        <Route path="/NewHome" element={<MainLayout />} />
        <Route path="/" titile="Home" element={<AccruonHomePAge />} />
        <Route path="/Services" element={<OurServicesPage />} />
        <Route path="/About-Us" element={<AboutUsPage />} />
        <Route path="/Blogs" element={<BlogPage />} s />
        <Route path="/Our-Team" element={<OurTeamsPage />} showSearch={true} />
        <Route path="/Careers" element={<CareersPage />} showSearch={true} />
        <Route
          path="/Contact-Us"
          element={<ContactUsPage />}
          showSearch={true}
        />
        <Route
          path="/Blog-Details"
          element={<BlogDetailsPage />}
          showSearch={true}
        />
        {/* //blogs */}
        <Route
          path="careers/:careerId/careers-details"
          element={<CareersDetailPage />}
          showSearch={true}
        />
        <Route
          path="/blogs/growing-a-business-in-the-uae"
          element={<GrowingBusinessPage />}
          showSearch={true}
        />
        <Route
          path="/blogs/anti-money-laundering-laws-aml-in-uae"
          element={<AmlPage />}
          showSearch={true}
        />
        <Route
          path="/blogs/role-of-accounting-in-it-business"
          element={<RoleAccountPage />}
          showSearch={true}
        />
        <Route
          path="/blogs/all-you-need-to-know-about-uae-corporate-tax"
          element={<CorporateTaxBlog />}
          showSearch={true}
        />
        <Route
          path="/blogs/importance-of-voluntary-disclosure-in-uae"
          element={<DisClosureUaeBlog />}
          showSearch={true}
        />
        <Route
          path="/blogs/how-to-claim-vat-refund-in-uae"
          element={<ClaimVatBlog />}
          showSearch={true}
        />
        <Route
          path="/blogs/purpose-of-book-keeping-in-united-arab-emirates"
          element={<BookKeepingBlog />}
          showSearch={true}
        />
        <Route
          path="/blogs/why-auditing-is-important-in-uae"
          element={<AuditUaeBlog />}
          showSearch={true}
        />
        <Route
          path="/blogs/erp-software-system-in-uae"
          element={<ErpSystempage />}
          showSearch={true}
        />
        <Route
          path="/blogs/decoding-uae-corporate-tax-law"
          element={<DecodingUaeCoporateTaxPage />}
        />
        <Route
          path="/blogs/unleash-Business-Opportunities"
          element={<UnleashBusinessOpportunities />}
        />
        <Route
          path="/blogs/top-ten-corporate-tax-consultants-in-uae"
          element={<TopTenCorporateTaxConsultantInUae />}
        />
        <Route
          path="/blogs/benefits-of-hiring-tax-consultant-in-uae"
          element={<BenefitsOfHiringTaxConsultantInUae />}
        />

        {/* //auditassurance */}
        <Route
          path="/services/audit-assurance/vat-audit"
          element={<VatAuditPage />}
          showSearch={true}
        />
        <Route
          path="/services/risk-assurance-2/internal-audit"
          element={<InternalFinancialAudit />}
          showSearch={true}
        />
        <Route
          path="/services/audit-assurance/external-audit"
          element={<ExternalAuditPage />}
          showSearch={true}
        />
        <Route
          path="/services/audit-assurance/company-liquidation"
          element={<CompanyLiquadationPage />}
          showSearch={true}
        />
        <Route
          path="/services/compliance-audit"
          element={<ComplainAuditPage />}
          showSearch={true}
        />
        <Route
          path="/services/ifrs-advisory-services"
          element={<IFRSAdvisoryServicesPage />}
          showSearch={true}
        />
        <Route
          path="/services/risk-management-audit"
          element={<RiskManagementAuditPage />}
          showSearch={true}
        />
        <Route
          path="/services/audit-assurance/fraud-investigation"
          element={<FraudInvestigationPage />}
          showSearch={true}
        />
        <Route
          path="/services/audit-assurance/free-zone-audit"
          element={<FreeZoneAuditPage />}
          showSearch={true}
        />
        <Route
          path="/services/audit-assurance/financial-tax-and-legal-due-diligence"
          element={<DueDiligenceAuditPage />}
          showSearch={true}
        />
        <Route
          path="/services/audit-assurance/anti-money-laundering-aml-audit/-laundering-aml-audit"
          element={<AmlAuditPage />}
          showSearch={true}
        />
        <Route
          path="/services/audit-assurance/statutory-audit-services"
          element={<StatutoryAuditServicesPage />}
          showSearch={true}
        />
        <Route
          path="/services/audit-assurance/mystery-audit"
          element={<MysteryAuditPage />}
          showSearch={true}
        />
        <Route
          path="/concurrent-audit"
          element={<ConcurrentAuditPage />}
          showSearch={true}
        />
        <Route
          path="/services/audit-assurance/quarterly-year-reporting"
          element={<QuaterlyYearReportingPage />}
          showSearch={true}
        />
        <Route
          path="/services/audit-assurance/forensic-audit"
          element={<ForensicAuditPage />}
          showSearch={true}
        />
        <Route
          path="/services/audit-assurance/sales-certification"
          element={<SalesCertificationPage />}
          showSearch={true}
        />
        <Route
          path="/services/audit-assurance/stock-audit-verification"
          element={<StockAuditPage />}
          showSearch={true}
        />
        <Route
          path="/services/audit-assurance/tailored-audit"
          element={<TailoredAuditPage />}
          showSearch={true}
        />

        {/* //BookKeeping & Account */}
        <Route
          path="/services/audit-assurance/accounting-services-in-uae"
          element={<AccountingServicePage />}
          showSearch={true}
        />
        <Route
          path="/services/accounting-and-bookkeeping-services/bookkeeping-service-uae"
          element={<BookKeepingPage />}
          showSearch={true}
        />
        <Route
          path="/services/accounting-and-bookkeeping-services/backlog-accounting-service-uae"
          element={<BackLogAccountPage />}
          showSearch={true}
        />
        <Route
          path="/accounting-supervision-services"
          element={<AccountSupervisionPage />}
          showSearch={true}
        />
        <Route
          path="/services/accounting-and-bookkeeping-services/analysis-and-design"
          element={<AnalysisDesignAccount />}
          showSearch={true}
        />
        <Route
          path="/cash-flow-and-forecasting-services"
          element={<CashFlowForecastingPage />}
          showSearch={true}
        />
        <Route
          path="/payroll-outsourcing-in-uae"
          element={<PayrollOutSourcePage />}
          showSearch={true}
        />
        {/* <Route path="/payroll-outsourcing-in-uae" element={<PayrollOutSourcePage/>} /> */}
        <Route
          path="/services/risk-assurance-2/fixed-assets-management"
          element={<FixedAssestsPage />}
          showSearch={true}
        />
        <Route
          path="/services/inventory-verification"
          element={<InventoryVerificationPage />}
          showSearch={true}
        />
        <Route
          path="/services/vat-consultants-uae/corporate-tax-consultant-related-services"
          element={<CorporateTaxPage />}
          showSearch={true}
        />

        {/* services */}
        <Route
          path="/services/corporate-tax-in-uae"
          element={<CorporateTaxinUAEPage />}
        />
        <Route
          path="/services/vat-consultants-in-uae"
          element={<VATRelatedServicePage />}
        />
        <Route
          path="/services/audit-and-assurance-service-in-uae"
          element={<AuditAssurancePage />}
        />
        <Route
          path="/services/national-in-country-value-program"
          element={<NationalInCountryValueProgramPage />}
        />
        <Route
          path="/services/economic-substance-regulations"
          element={<EconomicSubstanceRegulationsPage />}
        />
        <Route
          path="/services/business-process-outsourcing"
          element={<BusinessProcessOutsourcingPage />}
        />
        <Route
          path="/services/software-and-ERP-consulting"
          element={<SoftwareandERPConsultingPage />}
        />
        <Route
          path="/services/excise-tax-related-services"
          element={<ExciseTaxRelatedServicesPage />}
        />
        <Route
          path="/services/management-consultancy-services"
          element={<ManagementConsultancyPage />}
        />
        <Route
          path="/services/investment-advisory-invest-in-india"
          element={<InvestmentAdvisoryInvestinIndiaPage />}
        />
        <Route
          path="/services/governance-risk-compliance"
          element={<GovernanceRiskCompliancePage />}
        />
        <Route
          path="/services/HR-advisory-and-consulting"
          element={<HRAdvisoryConsultingPage />}
        />
        <Route
          path="/services/corporate-finance-consulting"
          element={<CorporateFinanceConsultingPage />}
        />
        <Route
          path="/services/business-registration-and-corporate-services"
          element={<BusinessRegistrationCorporateServicesPage />}
        />
        <Route
          path="/services/accounting-and-bookkeeping-services"
          element={<AccountingBookkeepingPage />}
        />

        {/* taxation */}
        {/* <Route path="/services/audit-assurance/vat-audit" element={<VATAuditinUAEPage />} /> */}
        <Route
          path="/services/vat-consultants-uae/vat-complient-bookkeeping"
          element={<VATComplientBookkeepingPage />}
        />
        <Route
          path="/vat-consultancy-services"
          element={<VATconsultancyservicesinDubaiPage />}
        />
        <Route
          path="/services/excise-tax-related-services"
          element={<ExciseTaxRelatedServicesPages />}
        />
        <Route
          path="/services/regulatory-compliance-services"
          element={<RegulatoryComplianceServicesPage />}
        />
        <Route
          path="/services/audit-assurance/tax-residency-domicile-certificate"
          element={<TaxResidencyDomicileCertificatePage />}
        />
        <Route
          path="/services/vat-consultants-uae/vat-reconsideration"
          element={<VATReconsiderationinUAEPages />}
        />
        <Route
          path="/services/vat-consultants-uae/vat-return-filing"
          element={<VATReturnFilinginUAEPages />}
        />
        <Route
          path="/services/vat-consultants-uae/vat-refund-service"
          element={<VATRefundServicesinUAEPages />}
        />
        <Route
          path="/services/vat-consultants-uae/vat-registration"
          element={<VATRegistrationinUAEPages />}
        />
        <Route
          path="/services/vat-consultants-uae/vat-de-registration"
          element={<VATDeregistrationServiceintheUAEPages />}
        />
        <Route
          path="/services/audit-assurance/advisory-on-double-tax-avoidance"
          element={<AdvisoryonDoubleTaxAvoidancePages />}
        />
        <Route
          path="/services/audit-assurance/vat-health-check-services-in-dubai-uae"
          element={<VATHealthCheckServicesinDubaiUAEPages />}
        />
        <Route
          path="/services/audit-assurance/assistance-of-vat-penalty-waiver"
          element={<AssistanceofVATPenaltyWaiverPages />}
        />
        <Route
          path="/services/audit-assurance/ultimate-beneficiary-owner-ubo-compliance"
          element={<UltimateBeneficiaryOwnerUBOCompliancePages />}
        />
        <Route
          path="/services/audit-assurance/explanation-and-exclusion"
          element={<ExplanationandExclusionPages />}
        />
        <Route
          path="/services/audit-assurance/voluntary-disclosure"
          element={<VoluntaryDisclosurePages />}
        />
        <Route
          path="/services/audit-assurance/esr-filing-company-dubai-united-arab-emirates"
          element={<ESRFilingCompanyDubaiUnitedArabEmiratesPages />}
        />

        {/* riskassurance */}
        <Route
          path="/Fixed-Assets-Management-Pages"
          element={<FixedAssetsManagementPages />}
        />

        {/* Acquisitions & Mergers */}
        <Route
          path="/services/audit-assurance/valuation-of-business-and-assets"
          element={<ValuationofBusinessandAssetsPages />}
        />
        <Route
          path="/services/acquisitions-and-mergers"
          element={<AcquisitionsandMergersPages />}
        />
        <Route
          path="/services/audit-assurance/reorganization-and-restructuring"
          element={<ReorganizationAndRestructuringPages />}
        />

        {/* Company Formations */}
        <Route
          path="/services/company-formation-in-uae"
          element={<UAECompanyFormationPages />}
        />
        <Route
          path="/services/audit-assurance/offshore"
          element={<OffshorePages />}
        />

        {/* VirtualCFO */}
        <Route path="/services/cfo-services" element={<VirtualCFOPages />} />

        {/* Coporate tax*/}
        <Route
          path="/Corporate-Tax-Consultancy"
          element={<CoporateTaxConsultancy />}
        />
        <Route
          path="/Corporate-Tax-ComplainBook"
          element={<CoporateTaxComplainBooking />}
        />
        <Route
          path="/Corporate-tax-registration"
          element={<CoporateTaxRegistrationPage />}
        />
        <Route
          path="/Corporate-tax-Compliance-Audit"
          element={<CoporateTaxComplainAudit />}
        />
        <Route
          path="/Corporate-tax-documentation"
          element={<CorporateTaxDocumentationPage />}
        />
        <Route
          path="/Corporate-tax-UAE-Return-Filing"
          element={<CorporateTaxUAEReturnFiling />}
        />
        <Route
          path="/Transfer-Pricing-Documentation"
          element={<TransferPricingDocumentationunderCTLaw />}
        />
        <Route
          path="/Related-Party-TransactionPage"
          element={<RelatedPartyTransactionPage />}
        />
        <Route
          path="/Connected-Persons-in-CT-Law"
          element={<ConnectedPesonsCtLaw />}
        />
        <Route
          path="/Tax-Group-in-Corporate-Tax-Law"
          element={<TaxGroupinCorporateTaxLaw />}
        />

        {/* landing page */}
        <Route
          path="/tax-consultants-in-uae"
          element={<LandingHomePageConsultants />}
        />
        <Route path="/Thankyou" element={<ThankYouPage />} />

        <Route
          exact
          path="*"
          title="page not found"
          element={<PageNotFoundPage />}
        />
      </Routes>
    </>
  );
}

export default App;
