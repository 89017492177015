import React from "react";
import { NavLink } from "react-router-dom";
import Othercontact from "../../assets/images/othercontact.webp";
import { ContactAddresses, Menu, PhoneNo, Services, SocialMedia } from ".";


export const Footer = () => {
  return (
    <>
      <section className="footer">
        <div className="row box-container container foot-contain">
          <div className="col-xl-4 col-lg-5 box">
            <img
              src={require("../../assets/images/Instagrampost-11.webp")}
              alt="Tax Consultants in UAE | Accruon Consultant LLC"
              style={{ width: "75%", marginTop: "-5px", marginLeft: "-31px" }}
            />
            <h4>ACCRUON CONSULTANT LLC</h4>
            <div className="foot-ic">
              <div>
                <img src={Othercontact} alt="" />
              </div>
              <NavLink to={"https://maps.app.goo.gl/mL86TMGFRN1Mii5r8"}>
                Accruon Consultant LLC, M6-29, Mezzanine Floor, ACICO Business
                Park, Port Saeed, Deira, Dubai, UAE
              </NavLink>
            </div>
            <SocialMedia/>
          </div>
          <Services/>
          <Menu/>
          <div className="col-xl-3 col-lg-5 box">
            <h6 className="about-hs">Other Contact</h6>
           <ContactAddresses/>
            <PhoneNo/>
          </div>
        </div>
        <div className="credit">
          Copyright © 2024 | Powered by{" "}
          <NavLink
            to="https://accruonconsultants.com/"
            style={{ color: "white" }}
          >
            Accruon Consultant LLC
          </NavLink>
        </div>
      </section>
    </>
  );
};


