import React from "react";
import coparate from "../../../assets/images/services/coparate.webp";
import vaticon from "../../../assets/images/services/vat-icon.webp";
import audit from "../../../assets/images/services/audit.webp";
import icv from "../../../assets/images/services/icv .webp";
import { useNavigate } from "react-router-dom";

function Service() {
   const navigate = useNavigate();
  return (
    <>
      <section class="pt-5 circle-1 main">
        <h2 class="left h2-b h2-b1">
          <span>Our Services</span>
        </h2>

        <h2 class="h-blog">We Offer Quality Services</h2>
        <div className="a-tag">
          <a href="/services">
            <h2 class="open-page">View All</h2>
          </a>
        </div>
        <div class="container-fluid">
          <ul class="cards">
            <li
              class="cards_item1-main"
              onClick={() => navigate("/services/corporate-tax-in-uae")}
            >
              <div class="card-service-main">
                <div class="">
                  <img src={coparate} alt="" class="img-service" />
                </div>
                <a href="/services/corporate-tax-in-uae">
                  <h3 class="blog-se">Corporate Tax in UAE</h3>
                </a>
                <div class="service-content">
                  Corporate tax is a type of direct tax applied on a
                  corporation’s or other business’s net revenue or profit. The…
                </div>
                {/* <footer class="serve-footer">
                  <a
                    href="/services/corporate-tax-in-uae"
                    class="btn s-read-main"
                  >
                    Learn More
                  </a>
                </footer> */}
              </div>
            </li>

            <li
              class="cards_item1-main"
              onClick={() => navigate("/services/vat-consultants-in-uae")}
            >
              <div class="card-service-main">
                <div class="">
                  <img src={vaticon} alt="" class="img-service" />
                </div>
                <a href="/services/vat-consultants-in-uae">
                  <h3 class="blog-se"> VAT Consultants in UAE</h3>
                </a>
                <div class="service-content">
                  Top Listed Audit Firms in Dubai and the Northern Emirates of
                  the UAE provide financial statement auditing…
                </div>
                {/* <footer class="serve-footer">
                  <a
                    href="/services/corporate-tax-in-uae"
                    class="btn s-read-main"
                  >
                    Learn More
                  </a>
                </footer> */}
              </div>
            </li>
            <li
              class="cards_item1-main"
              onClick={() =>
                navigate("/services/audit-and-assurance-service-in-uae")
              }
            >
              <div class="card-service-main">
                <div class="">
                  <img src={audit} alt="" class="img-service" />
                </div>
                <a href="/services/audit-and-assurance-service-in-uae">
                  <h3 class="blog-se">Audit & Assurance</h3>
                </a>
                <div class="service-content">
                  One of the fundamental services that every organisation
                  requires to keep track of their financial transactions is
                  audit and assurance….
                </div>
                {/* <footer class="serve-footer">
                  <a
                    href="/services/audit-and-assurance-service-in-uae"
                    class="btn s-read-main"
                  >
                    Learn More
                  </a>
                </footer> */}
              </div>
            </li>
            <li
              class="cards_item1-main"
              onClick={() =>
                navigate("/services/national-in-country-value-program")
              }
            >
              <div class="card-service-main">
                <div class="">
                  <img src={icv} alt="" class="img-service" />
                </div>
                <a href="/services/national-in-country-value-program">
                  {" "}
                  <h3 class="blog-se">
                    National In-Country Value Program (ICV)
                  </h3>
                </a>
                <div class="service-content">
                  The National In-Country Value (ICV) Program is a UAE
                  government program that aims to boost economic performance and
                  support local…
                </div>
                {/* <footer class="serve-footer">
                  <a
                    href="/services/national-in-country-value-program"
                    class="btn s-read-main"
                  >
                    Learn More
                  </a>
                </footer> */}
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}

export default Service;
