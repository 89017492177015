import React from 'react'

export const NavPageHead = React.memo(({ headClass, headName, sideHead }) => {
  return (
    <React.Fragment>
      <h2 class="left h1-b">
        <span>{sideHead}</span>
      </h2>

      <h1 class={headClass}>{headName}</h1>
    </React.Fragment>
  );
});
