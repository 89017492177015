import React, { useState } from 'react'

export const useHeader = () => {
    const [navShow, setNavShow] = useState(false);
    const [navShow2, setNavShow2] = useState(false);
    const handleNavBarClick = () => {
      console.log("clicked");
      if (navShow == true) {
        setNavShow(false);
      } else {
        setNavShow(true);
        setNavShow2(false);
      }
    };
    return {
      navShow,
      navShow2,
      handleNavBarClick,
    };
}
