import React from 'react'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import NavBar from '../components/navbar'
import Footer from '../components/footer'

import tax from "../../assets/images/about/vat-consultants-in-uae.webp"
import rijask from "../../assets/images/about/rijas-k.webp"
import bijukn from "../../assets/images/about/biju-kn.webp"
import Rasmig from "../../assets/images/about/Rasmi-g.webp"
import anoopp from "../../assets/images/about/anoop-p.webp"
import raveendranp from "../../assets/images/about/raveendran-p.webp"
// import about1 from "../../assets/images/about/
import ContactSectionPages from '../components/contact'
import { Helmet } from 'react-helmet'
function AboutUsPage() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
    return (
      <>
        <Helmet>
          <title>About Us | Accruon Consultant LLC</title>
          <meta name="description" content="" />
          <link
            rel="canonical"
            href="https://accruonconsultants.com/About-Us"
          />
        </Helmet>
        {/* <NavBar></NavBar> */}
        {/* History */}
        <section class="about main">
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-12 md-pb">
              <div class="title-wrapper align-left">
                <h2 class="left h1-b">
                  <span>History of Company</span>
                </h2>
                <h1 class="pb-3 about-h1">
                  DELIVERING TAX AND FINANCIAL CONSULTING EXPERTISE SINCE 2000
                </h1>
                <p class="about-p1">
                  Accruon Consultant LLC is one of the leading, reliable tax
                  consultants in Dubai, UAE which guides you about the taxation
                  policies prevailing in the country. Our highly skilled
                  professionals with extensive knowledge of the UAE tax laws and
                  regulations assist you with corporate tax consultancy, VAT
                  consultancy, accounting, bookkeeping, auditing, management
                  consultancy services, and many more throughout the UAE. Our
                  parent company, K. RIJAS & ASSOCIATES, Chartered Accountants,
                  is a well-known firm in India that provides a wide range of
                  services in the areas of auditing and assurance, management
                  consulting, and direct and indirect taxation. We assure to
                  provide effective financial services in a dynamic tax
                  environment in Dubai.
                </p>
              </div>
            </div>

            <div class="col-lg-6 col-md-12 md-pb">
              <img alt="Tax Consultants in UAE" src={tax} />
            </div>
          </div>
        </section>

        <section class="about main">
          <div class="mission-s">
            <h3 class="about-h">OUR MISSION & VISION</h3>

            <p class="about-p">
              Accruon Consultant LLC is dedicated to elevating customer
              satisfaction by providing unwavering support in Accounting, VAT,
              bookkeeping, and comprehensive management consulting services, all
              offered at highly competitive rates.
            </p>
          </div>
        </section>

        <section class="about main">
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-12 md-pb">
              <img
                alt=""
                src={require("../../assets/images/about/about-1.webp")}
                class="about1-img mob-img"
              />
            </div>

            <div class="col-lg-6 col-md-12 md-pb">
              <div class="title-wrapper align-left">
                <h3 class="pb-3 about-h1">Accounting Firm in Dubai</h3>
                <p class="about-p1">
                  Accruon Consultant LLC is the best accounting firm in Dubai,
                  UAE. We provide services throughout the UAE with a focus on
                  corporate tax, value-added tax, software-related services,
                  accounting, bookkeeping, and management consulting. Our
                  success is largely attributed to our loyal clientele, nurtured
                  meticulously over time.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* History */}

        {/* footer section start */}
        <section class="our-team-section main">
          <h2 class="left h1-b">
            <span>Our Team</span>
          </h2>
          <h1 class="h-blog">Meet with professional experts</h1>
          <a href="/our-team">
            <h2 class="open-page">View All</h2>
          </a>
          <div class="container">
            <div class="row">
              <div class="col-md-3 col-md-6 col-sm-6 team-pic">
                <div class="our-team">
                  <div class="pic">
                    <img alt="" src={rijask} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Rijas Kochunni</h3>
                    <span class="post">M.Com, FCA</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-md-6 col-sm-6 team-pic">
                <div class="our-team">
                  <div class="pic">
                    <img alt="" src={bijukn} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">BIJU K N</h3>
                    <span class="post">B.COM, FCA</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-md-6 col-sm-6 team-pic">
                <div class="our-team">
                  <div class="pic">
                    <img alt="" src={Rasmig} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">RASMI G NAIR</h3>
                    <span class="post">B.COM, FCA</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-md-6 col-sm-6 team-pic">
                <div class="our-team">
                  <div class="pic">
                    <img alt="" src={anoopp} />
                  </div>
                  <div class="team-content">
                    <h3 class="title"> ANOOP PADMAKUMAR </h3>
                    <span class="post"> B.Com, ACA, ACMA, DISA, CISA </span>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-md-6 col-sm-6 team-pic">
                <div class="our-team">
                  <div class="pic">
                    <img alt="" src={raveendranp} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">RAVEENDRAN P</h3>
                    <span class="post">B.SC, FCA, DISA</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* footer section end */}

        {/* contact */}

        <ContactSectionPages />

        {/* contact end*/}
        {/* <Footer></Footer> */}
      </>
    );
}

export default AboutUsPage