import React from "react";
import mailbarkIcon from "../../../assets/images/home/email-new.webp";
import callbarkIcon from "../../../assets/images/home/ph-new.webp";
import whatupIcon from "../../../assets/images/home/whats-new.webp";
export const IconBarContainer = () => {
  const iconBarContent = [
    {
      href: "tel:+971 52913 7700",
      class: "phone11",
      target: "_blank",
      imgSrc: callbarkIcon,
    },
    {
      href: "mailto:mail@accruonconsultants.com",
      class: "email11",
      target: "_blank",
      imgSrc: mailbarkIcon,
    },
    {
      href: "https://api.whatsapp.com/send/?phone=%2B971529137700&text&type=phone_number&app_absent=0",
      class: "whats11",
      target: "_blank",
      imgSrc: whatupIcon,
    },
  ];
  return (
    <React.Fragment>
      <div class="icon-bar">
        {iconBarContent?.length > 0
          ? iconBarContent?.map((item, index) => (
              <a
                href={item?.href}
                className={item?.class}
                target={item?.target}
                key={index}
              >
                <img alt="" src={`${item?.imgSrc}`} />
              </a>
            ))
          : null}
      </div>
    </React.Fragment>
  );
};
