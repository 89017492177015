import React from 'react'
import NavBar from '../../../components/navbar'
import Footer from '../../../components/footer'

import tax from '../../../../assets/images/services/Investment-Advisory-Invest-in-India.webp'
import ContactSectionPages from '../../../components/contact'
import { Helmet } from 'react-helmet'
function InvestmentAdvisoryInvestinIndiaPage() {
    return (
      <>
        <Helmet>
          <title>
            Investment Advisory - Invest in India | Accruon Consultant LLC{" "}
          </title>
          <meta name="description" content="" />
          <link
            rel="canonical"
            href="https://accruonconsultants.com/services/investment-advisory-invest-in-india"
          />
        </Helmet>
        {/* <NavBar></NavBar> */}

        <section class="about container margin-top1">
          <div class="row">
            <div class="col-lg-6 col-md-12 md-pb">
              <div class="title-wrapper align-left">
                <h2 class="left h1-b">
                  <span>Service details</span>
                </h2>
                <h3 class="pb-3 about-h1">
                  Investment Advisory - Invest in India
                </h3>

                <p class="about-p1">
                  The Government of India is actively establishing a network of
                  industrial corridors nationwide, providing well-developed land
                  and top-tier infrastructure for industrial townships.Key
                  Services Offered:
                </p>

                <p class="about-p1">&#x2713; Company Registration in India</p>
                <p class="about-p1">
                  &#x2713;Foreign Direct Investment (FDI) Approvals from the
                  Reserve Bank of India
                </p>
                <p class="about-p1">&#x2713; GST Registration and Compliance</p>
                <p class="about-p1">
                  &#x2713; Expert opinion on Indian Income Tax
                </p>
                <p class="about-p1">&#x2713; Accounting & Book keeping</p>
                <p class="about-p1">&#x2713; Back office works</p>
                <p class="about-p1">&#x2713; Assist in selecting Banker</p>
                <p class="about-p1">&#x2713; Assist in getting finances</p>
                <p class="about-p1">
                  &#x2713; Advisory on Labour laws in India
                </p>
                <p class="about-p1">
                  &#x2713; Advisory for other statutory matters
                </p>
              </div>
            </div>

            <div class="col-lg-6 col-md-12 md-pb">
              <img
                src={tax}
                class="mob-img"
                alt="Investment Advisory - Invest in India"
              />
            </div>
            <div class="col-lg-6 col-md-12 md-pb">
              <p class="about-p1">
                <strong>Our team assists with:</strong>
              </p>

              <p class="about-p1">
                <i>Market strategy</i>
              </p>
              <p class="about-p1">
                <i>Location identification</i>
              </p>
              <p class="about-p1">
                <i>Business plan advisory</i>
              </p>
              <p class="about-p1">
                <i>Expediting regulatory approvals</i>
              </p>
              <p class="about-p1">
                <i>Initiating remedial action on problems faced by investors</i>
              </p>
              <p class="about-p1">
                <i>
                  Facilitating meetings with relevant government and corporate
                  officials
                </i>
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 md-pb">
            <p class="about-h1">
              <strong>Why Consider Investing in India?</strong>
            </p>
          </div>
          <section class="about container d-c1">
            <div class="list-service">
              <ol>
                <li>
                  {" "}
                  <div class="arrow-triangle left"></div> Fastest growing
                  economy
                </li>
                <p class="about-p1">
                  &#x2022; India is among the world's fastest-growing economies,
                  exhibiting resilience during global downturns.
                </p>
                <p class="about-p1">
                  &#x2022;FDI inflow reached USD 31,153 million from April to
                  September 2021, growing by 4%.
                </p>
                <p class="about-p1">
                  &#x2022; The government introduced a comprehensive package of
                  over USD 270 billion under the Atmanirbhar Bharat Abhiyan.
                </p>

                <li>
                  {" "}
                  <div class="arrow-triangle left"></div> Demographic Advantage
                </li>
                <p class="about-p1">
                  &#x2022; India's population is projected to grow by 25.7% from
                  2011 to 2036, with a large youth population.
                </p>
                <p class="about-p1">
                  &#x2022; Average household incomes are expected to triple by
                  2030, positioning India as the fifth-largest consumer economy.
                </p>

                <li>
                  {" "}
                  <div class="arrow-triangle left"></div>Favourable Policies
                </li>
                <p class="about-p1">
                  &#x2022; Significant FDI policy reforms in various sectors,
                  allowing 100% FDI in several areas through the automatic
                  route.
                </p>
                <p class="about-p1">
                  &#x2022; Sector-specific policies, incentives, and subsidies
                  from Central and State governments to boost manufacturing.
                </p>

                <li>
                  {" "}
                  <div class="arrow-triangle left"></div> Infrastructure
                  Development
                </li>
                <p class="about-p1">
                  &#x2022; The National Infrastructure Pipeline aims to provide
                  world-class infrastructure and support the USD 5 trillion
                  economy target.
                </p>
                <p class="about-p1">
                  &#x2022; Around 7,000 projects with a cost of INR 100 crore
                  each are underway, focusing on energy, roads, urban
                  development, and railways.
                </p>

                <li>
                  {" "}
                  <div class="arrow-triangle left"></div> Supporting Government
                  Structure
                </li>
                <p class="about-p1">
                  &#x2022;India, as the world's largest democracy, has a robust
                  legal and political system ensuring long-term stability.
                </p>
                <p class="about-p1">
                  &#x2022; Independent financial institutions, including the
                  RBI, SEBI, and CCI, contribute to economic stability and
                  market regulation.
                </p>

                <li>
                  {" "}
                  <div class="arrow-triangle left"></div> Global Innovation
                  Index
                </li>
                <p class="about-p1">
                  &#x2022; India ranks 48th in the Global Innovation Index 2020,
                  showing significant improvement.
                </p>
                <p class="about-p1">
                  &#x2022; Achieving the top position in Central & Southern Asia
                  and ranking 3rd in the Lower Middle-Income Economy Group
                  highlights India's innovation prowess.
                </p>
              </ol>
            </div>
          </section>
        </section>
        <ContactSectionPages />
        {/* <Footer></Footer> */}
      </>
    );
}

export default InvestmentAdvisoryInvestinIndiaPage