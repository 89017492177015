import React, { useEffect } from "react";
import bannerImage from "../../../assets/images/home/banner-mobile-imagenew.webp";
import "../../../index.css";
import { CarouselComponent } from "../../../shared/components/CarouselComponent";
import { IconBarContainer } from ".";
export const BannerComponent = () => {
  useEffect(() => {
    const img = new Image();
    img.src = bannerImage;
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    pauseOnHover: false,
  };
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,

      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 1,
      slidesToSlide: 1,
    },

    tablet: {
      breakpoint: { max: 1300, min: 880 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 880, min: 0 },
      items: 1,
    },
  };
  const BannerContainer = ({ children }) => {
    return (
      <div fetchpriority="high" className="main-v2">
        {children}
      </div>
    );
  };
  const Contents = [
    {
      head: "TRUSTED TAX CONSULTANTS FOR",
      secondline: "CORPORATE INCOME",
      thirdline: "TAX & VAT",
    },
    {
      head: "A STRATEGIC PATH OF TRUE INVESTMENT",
      secondline: "FINANCIAL ADVISOR",
      thirdline: "",
    },
    {
      head: "ACCOUNTING OUTCOMES THAT YOU DESIRE",
      secondline: "ACCOUNTING MADE",
      thirdline: "SIMPLE & SMART",
    },
    {
      head: "EXPERT TAX CONSULTANTS IN UAE",
      secondline: "FOR FINANCIAL SUCCESS",
      thirdline: "",
    },
  ];
  return (
    <React.Fragment>
      <BannerContainer>
        <CarouselComponent
          autoPlay={true}
          infinite={true}
          responsive={responsive}
          settings={settings}
        >
          {Contents?.map((content, index) => (
            <div className="slide-parent slide-align" key={index}>
              <div className="slide-content">
                <h2 className="slide-text slide-text--1">
                  <span>{content?.head}</span>
                </h2>
                <p className="slide-text slide-text--2">
                  <span className="main-h">
                    {content?.secondline}
                    <br />
                    {content?.thirdline}
                  </span>
                </p>
              </div>
            </div>
          ))}
        </CarouselComponent>
        <IconBarContainer />
      </BannerContainer>
    </React.Fragment>
  );
};
