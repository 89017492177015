import React from 'react'
import Counter from '../../../assets/components/counter'

function Strip() {
    return (
        <>
            <div class="count-up">

                <div class="wrapper">
                    <div class="plus-one">
                        <Counter class='counter' number={35} />
                        <span class="plus-p">+</span></div>

                    <p class="strip-p">Team Members</p>
                </div>



                
                <div class="wrapper">
                    <div class="plus-one">
                        <Counter class='counter' number={12000} />
                        <span class="plus-p">+</span></div>

                    <p class="strip-p">Audits</p>
                </div>

                <div class="wrapper">
                    <div class="plus-one">
                        <Counter class='counter' number={9800} />
                        <span class="plus-p">+</span></div>

                    <p class="strip-p">Happy Clients</p>
                </div>

            </div>
        </>
    )
}

export default Strip