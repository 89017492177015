import React from "react";
import UnleashBusiness from "../../../assets/images/home/business-setup-in dubai-free-zone-accruon-consultants-llc.jpg";
import TopTen from "../../../assets/images/home/top-ten-corporate-tax-consultants-in-uae-accruon-consultants-llc.jpg";
import BenefitsOfHiring from "../../../assets/images/home/benefits-of-hiring-tax-consultant-in-uae-accruon-consultants-llc.jpg";
import CorporateTaxPenalty from "../../../assets/images/home/corporate_tax_penalty_of_aed_ten_thousand_for_late_tax_registration_in_uae.webp";
import { useNavigate } from "react-router-dom";

export const BlogStart=()=> {
  const navigate = useNavigate();
<li class="cards_item">
  <div class="card">
    <div class="card_image">
      <img src={CorporateTaxPenalty} alt="" class="img-blog" />
    </div>
    <h3 class="blog-h">
      Corporate Tax penalty of AED 10,000 for late tax registration in UAE!
    </h3>
    <div class="card-content">
      In the bustling landscape of business in the United Arab Emirates (UAE),
      staying abreast of regulatory changes is crucial. One recent development
      stirring the corporate realm is the implementation of a corporate tax
      penalty of AED 10,000 for late tax registration in the UAE.
    </div>
    <footer class="card-footer">
      <a
        href="/blogs/corporate-tax-penalty-of-aed-ten-thousand-for-late-tax-registration-in-uae"
        class="btn b-read"
      >
        Read More
      </a>
    </footer>
  </div>
</li>;
   const blogs = [
     {
       title:
         "Corporate Tax penalty of AED 10,000 for late tax registration in UAE!",
       image: CorporateTaxPenalty,
       path: "/blogs/corporate-tax-penalty-of-aed-ten-thousand-for-late-tax-registration-in-uae",
       content:
         "In the bustling landscape of business in the United Arab Emirates (UAE),staying abreast of regulatory changes is crucial. One recent development stirring the corporate realm is the implementation of a corporate tax penalty of AED 10,000 for late tax registration in the UAE.",
     },
     {
       title:
         "Benefits of Hiring Tax Consultant in UAE: Maximize Savings & Minimize Risk",
       image: BenefitsOfHiring,
       path: "/blogs/benefits-of-hiring-tax-consultant-in-uae",
       content:
         "Are you worried about tax complexities? Do you know the benefits of hiring tax consultants in UAE? Tax policies may create ambiguity but partnering with Accruon simplifies the process.",
     },
     {
       title: "Top 10 Corporate Tax Consultants in UAE",
       image: TopTen,
       path: "/blogs/top-ten-corporate-tax-consultants-in-uae",
       content:
         "The Ministry of Finance has released guidelines to assist companies in registering for corporate tax and obtaining relevant information, about the implementation of Corporate tax in UAE.",
     },
   ];

  const handleBlogClick = (path) => {
    navigate(path);
  };

  return (
    <>
      <section className="pt-5 circle-1 main">
        <h2 className="left h2-b">
          <span>Our Blogs</span>
        </h2>
        <h2 className="h-blog">Latest Blog & Articles</h2>
        <a href="/blogs">
          <h2 className="open-page">View All</h2>
        </a>
        <ul className="cards">
          {blogs.map((blog, index) => (
            <li
              key={index}
              className="cards_item"
              onClick={() => handleBlogClick(blog.path)}
            >
              <div className="card">
                <div className="card_image">
                  <img src={blog.image} alt="" className="img-blog" />
                </div>
                <h3 className="blog-h">{blog.title}</h3>
                <div className="card-content">{blog.content}</div>
              </div>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
}


