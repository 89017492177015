import React from "react";
import { Helmet } from "react-helmet";

const PageHelmet = ({ title, description, canonical }) => {
  console.log(title,description);
  return (
    <Helmet>
      <meta charSet="utf-8" />
      {title&&<title>{title}</title>}
      {description&&<meta name="description" content={description} />}
      {canonical && <link rel="canonical" href={canonical} />}
    </Helmet>
  );
};

export default PageHelmet;
