import React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavBar from "../components/navbar";
import Footer from "../components/footer";

import cfoIcon from "../../assets/images/services/virtual cfo.svg";
import Acquisitions from "../../assets/images/services/accussition and merge.svg";
import cmpnyformation from "../../assets/images/services/company formation.svg";
import coparate from "../../assets/images/services/coparate.webp";
import vaticon from "../../assets/images/services/vat-icon.webp";
import audit from "../../assets/images/services/audit.webp";
import icv from "../../assets/images/services/icv .webp";
import economic from "../../assets/images/services/economic.webp";
import business from "../../assets/images/services/business.webp";
import software from "../../assets/images/services/software.webp";
import excise from "../../assets/images/services/excise.webp";
import management from "../../assets/images/services/management.webp";
import investment from "../../assets/images/services/investment.webp";
import governance from "../../assets/images/services/governance.webp";
import hr from "../../assets/images/services/hr.webp";
import finance from "../../assets/images/services/finance.webp";
import registration from "../../assets/images/services/registration.webp";
import accounting from "../../assets/images/services/accounting.webp";
import ContactSectionPages from "../components/contact";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function ServicePage() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <Helmet>
        <title>Services | Accruon Consultant LLC</title>
        <meta name="description" content="" />
        <link rel="canonical" href="https://accruonconsultants.com/Services" />
      </Helmet>
      {/* <NavBar></NavBar> */}
      <section class="main">
        {/* <div class="main"> */}
        <h2 class="left h1-b">
          <span>Services </span>
        </h2>
        <h1 class="h-blog-s">
          Quality Services Helping Your Business Grow To Greater Heights.
        </h1>
        <ul class="cards">
          <li
            class="cards_item1"
            onClick={() => navigate("/services/corporate-tax-in-uae")}
          >
            <div class="card-service">
              <div class="">
                <img src={coparate} class="img-service" alt="" />
              </div>
              <h3 class="blog-se">Corporate Tax in UAE</h3>
              <div class="service-content">
                Corporate Tax in UAE is a type of direct tax applied on
                corporations or other businesses’ net revenue or profit. In most
                cases, such taxes are calculated using a company’s...
              </div>
              <footer class="serve-footer">
                <a href="/services/corporate-tax-in-uae" class="btn s-read">
                  Learn More
                </a>
              </footer>
            </div>
          </li>

          <li
            class="cards_item1"
            onClick={() => navigate("/services/vat-consultants-in-uae")}
          >
            <div class="card-service">
              <div class="">
                <img src={vaticon} class="img-service" alt="" />
              </div>
              <h3 class="blog-se"> VAT Consultants in UAE</h3>
              <div class="service-content">
                Value Added Taxes are the taxes that are collected by the
                government from businesses directly or indirectly through other
                businesses....
              </div>
              <footer class="serve-footer">
                <a
                  href="/services/vat-consultants-in-uae"
                  class="btn s-read"
                  onClick={scrollToTop}
                >
                  Learn More
                </a>
              </footer>
            </div>
          </li>

          <li
            class="cards_item1"
            onClick={() =>
              navigate("/services/audit-and-assurance-service-in-uae")
            }
          >
            <div class="card-service">
              <div class="">
                <img src={audit} class="img-service" alt="" />
              </div>
              <h3 class="blog-se">Audit & Assurance</h3>
              <div class="service-content">
                One of the fundamental services that every organisation requires
                to keep track of their financial transactions is audit and
                assurance. Our Auditing service in Dubai..
              </div>
              <footer class="serve-footer">
                <a
                  href="/services/audit-and-assurance-service-in-uae"
                  class="btn s-read"
                >
                  Learn More
                </a>
              </footer>
            </div>
          </li>
          <li
            class="cards_item1"
            onClick={() =>
              navigate("/services/national-in-country-value-program")
            }
          >
            <div class="card-service">
              <div class="">
                <img src={icv} class="img-service" alt="" />
              </div>
              <h3 class="blog-se">National In-Country Value Program (ICV)</h3>
              <div class="service-content">
                The National In-Country Value (ICV) Certification Program,
                initiated by the UAE government, aims to enhance economic growth
                and bolster local industries by…
              </div>
              <footer class="serve-footer">
                <a
                  href="/services/national-in-country-value-program"
                  class="btn s-read"
                >
                  Learn More
                </a>
              </footer>
            </div>
          </li>
          <li
            class="cards_item1"
            onClick={() => navigate("/services/economic-substance-regulations")}
          >
            <div class="card-service">
              <div class="">
                <img src={economic} class="img-service" alt="" />
              </div>
              <h3 class="blog-se">Economic Substance Regulations</h3>
              <div class="service-content">
                The Economic Substance Regulations (ESR) were introduced in the
                United Arab Emirates (UAE) via Cabinet Decision Number 31 of
                2019, prompted by…
              </div>
              <footer class="serve-footer">
                <a
                  href="/services/economic-substance-regulations"
                  class="btn s-read"
                >
                  Learn More
                </a>
              </footer>
            </div>
          </li>
          <li
            class="cards_item1"
            onClick={() => navigate("/services/business-process-outsourcing")}
          >
            <div class="card-service">
              <div class="">
                <img src={business} class="img-service" alt="" />
              </div>
              <h3 class="blog-se">Business Process Outsourcing</h3>
              <div class="service-content">
                We, Accruon Consultant, have decades of experience in the
                accounts and finance related activity outsourcing. Our expert
                team can handle…
              </div>
              <footer class="serve-footer">
                <a
                  href="/services/business-process-outsourcing"
                  class="btn s-read"
                >
                  Learn More
                </a>
              </footer>
            </div>
          </li>
          <li
            class="cards_item1"
            onClick={() => navigate("/services/software-and-ERP-consulting")}
          >
            <div class="card-service">
              <div class="">
                <img src={software} class="img-service" alt="" />
              </div>
              <NavLink to="https://accruon.ae/" target="_blank">
                <h3 class="blog-se">Software and ERP Consulting</h3>
              </NavLink>
              <div class="service-content">
                We provide solutions that are tailored to your company’s needs.
                Accruon Technologies provides customers with bespoke software
                development solutions.
              </div>
              <footer class="serve-footer">
                <a
                  href="/services/software-and-ERP-consulting"
                  class="btn s-read"
                >
                  Learn More
                </a>
              </footer>
            </div>
          </li>
          <li
            class="cards_item1"
            onClick={() => navigate("/services/excise-tax-related-services")}
          >
            <div class="card-service">
              <div class="">
                <img src={excise} class="img-service" alt="" />
              </div>
              <h3 class="blog-se">Excise Tax Related Services</h3>
              <div class="service-content">
                The UAE government levies an indirect tax on commodities that
                are considered hazardous to human health or the environment.
                The…
              </div>
              <footer class="serve-footer">
                <a
                  href="/services/excise-tax-related-services"
                  class="btn s-read"
                >
                  Learn More
                </a>
              </footer>
            </div>
          </li>

          <li
            class="cards_item1"
            onClick={() =>
              navigate("/services/management-consultancy-services")
            }
          >
            <div class="card-service">
              <div class="">
                <img src={management} class="img-service" alt="" />
              </div>
              <h3 class="blog-se">Management Consultancy Service</h3>
              <div class="service-content">
                We, Accruon Consultants, have vast experience in the field of
                Management Consulting. For us, management consulting is the..
              </div>
              <footer class="serve-footer">
                <a
                  href="/services/management-consultancy-services"
                  class="btn s-read"
                >
                  Learn More
                </a>
              </footer>
            </div>
          </li>
          <li
            class="cards_item1"
            onClick={() =>
              navigate("/services/investment-advisory-invest-in-india")
            }
          >
            <div class="card-service">
              <div class="">
                <img src={investment} class="img-service" alt="" />
              </div>
              <h3 class="blog-se">Investment Advisory – Invest in India</h3>
              <div class="service-content">
                The Government of India is actively establishing a network of
                industrial corridors nationwide, providing well-developed land
                and top-tier infrastructure for industrial townships…
              </div>
              <footer class="serve-footer">
                <a
                  href="/services/investment-advisory-invest-in-india"
                  class="btn s-read"
                >
                  Learn More
                </a>
              </footer>
            </div>
          </li>

          <li
            class="cards_item1"
            onClick={() => navigate("/services/governance-risk-compliance")}
          >
            <div class="card-service">
              <div class="">
                <img src={governance} class="img-service" alt="" />
              </div>
              <h3 class="blog-se">Governance, Risk, Compliance (GRC)</h3>
              <div class="service-content">
                GRC service assists organizations to effectively monitor and
                manage risk and ensure compliance to regulatory requirements. It
                may so happen…
              </div>
              <footer class="serve-footer">
                <a
                  href="/services/governance-risk-compliance"
                  class="btn s-read"
                >
                  Learn More
                </a>
              </footer>
            </div>
          </li>

          <li
            class="cards_item1"
            onClick={() => navigate("/services/HR-advisory-and-consulting")}
          >
            <div class="card-service">
              <div class="">
                <img src={hr} class="img-service" alt="" />
              </div>
              <h3 class="blog-se">HR Advisory & Consulting</h3>
              <div class="service-content">
                Human Resources (HR) advisory and consulting services in the
                United Arab Emirates (UAE) are in demand due to the country's
                thriving business environment..
              </div>
              <footer class="serve-footer">
                <a
                  href="/services/HR-advisory-and-consulting"
                  class="btn s-read"
                >
                  Learn More
                </a>
              </footer>
            </div>
          </li>

          <li
            class="cards_item1"
            onClick={() => navigate("/services/corporate-finance-consulting")}
          >
            <div class="card-service">
              <div class="">
                <img src={finance} class="img-service" alt="" />
              </div>
              <h3 class="blog-se">Corporate Finance Consulting</h3>
              <div class="service-content">
                Corporate finance consulting in the UAE involves providing
                specialized financial advice and services to businesses in the
                region..
              </div>
              <footer class="serve-footer">
                <a
                  href="/services/corporate-finance-consulting"
                  class="btn s-read"
                >
                  Learn More
                </a>
              </footer>
            </div>
          </li>

          <li
            class="cards_item1"
            onClick={() =>
              navigate("/services/business-registration-and-corporate-services")
            }
          >
            <div class="card-service">
              <div class="">
                <img src={registration} class="img-service" alt="" />
              </div>
              <h3 class="blog-se">
                Business Registration & Corporate Services
              </h3>
              <div class="service-content">
                Starting a business in the United Arab Emirates (UAE) can be
                made hassle-free by the support of Accruon Consultant. We…
              </div>
              <footer class="serve-footer">
                <a
                  href="/services/business-registration-and-corporate-services"
                  class="btn s-read"
                >
                  Learn More
                </a>
              </footer>
            </div>
          </li>
          <li
            class="cards_item1"
            onClick={() =>
              navigate("/services/accounting-and-bookkeeping-services")
            }
          >
            <div class="card-service">
              <div class="">
                <img src={accounting} class="img-service" alt="" />
              </div>
              <h3 class="blog-se">Accounting & Bookkeeping</h3>
              <div class="service-content">
                Accounting and bookkeeping services in UAE, keep track of funds
                and are essential for any company, whether it is a…
              </div>
              <footer class="serve-footer">
                <a
                  href="/services/accounting-and-bookkeeping-services"
                  class="btn s-read"
                >
                  Learn More
                </a>
              </footer>
            </div>
          </li>
          <li
            class="cards_item1"
            onClick={() => navigate("/services/company-formation-in-uae")}
          >
            <div class="card-service">
              <div class="">
                <img src={cmpnyformation} class="img-service" alt="" />
              </div>
              <h3 class="blog-se">Company Formation In UAE</h3>
              <div class="service-content">
                What makes UAE the perfect setting for entrepreneurs? Investors
                in the UAE are endowed with three different functional..
              </div>
              <footer class="serve-footer">
                <a href="/services/company-formation-in-uae" class="btn s-read">
                  Learn More
                </a>
              </footer>
            </div>
          </li>
          <li
            class="cards_item1"
            onClick={() => navigate("/services/cfo-services")}
          >
            <div class="card-service">
              <div class="">
                <img src={cfoIcon} class="img-service" alt="" />
              </div>
              <h3 class="blog-se">Virtual CFO(Chief Finance Officer)</h3>
              <div class="service-content">
                A Virtual Chief Finance Officer(CFO) is an experienced finance
                expert who lends a hand in the smooth functioning of finance ...
              </div>
              <footer class="serve-footer">
                <a href="/services/cfo-services" class="btn s-read">
                  Learn More
                </a>
              </footer>
            </div>
          </li>
          <li
            class="cards_item1"
            onClick={() => navigate("/services/acquisitions-and-mergers")}
          >
            <div class="card-service">
              <div class="">
                <img src={Acquisitions} class="img-service" alt="" />
              </div>
              <h3 class="blog-se">Acquisitions & Mergers</h3>
              <div class="service-content">
                To conduct Mergers and Acquisitions process in a smooth manner,
                it is important to keep track of Joint Ventures and Sales and
                Purchase Agreements. ...
              </div>
              <footer class="serve-footer">
                <a href="/services/acquisitions-and-mergers" class="btn s-read">
                  Learn More
                </a>
              </footer>
            </div>
          </li>
        </ul>
        {/* </div> */}
      </section>
      {/* contact */}

      <ContactSectionPages />

      {/* contact end*/}

      {/* <Footer></Footer> */}
    </>
  );
}

export default ServicePage;
