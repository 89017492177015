

import React from 'react'



function ContactSectionPages() {
    return (
        <>
            <section class="last-sec">
                <div class="last-c container">
                    <h3 class="about-hc">Professional Accounting Firm in Dubai,UAE</h3>

                    <p class="about-p">We will assign a dedicated and qualified team to your entity that will assist you in ensuring statutory compliance and providing necessary management reports.</p>

                    <a href="/contact-us" class="btn btn-contact">Contact Us</a>
                </div>
            </section>
        </>
    )
}

export default ContactSectionPages