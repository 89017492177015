import NavBar from "../components/navbar";
import Footer from "../components/footer";
import BannerSection from './banner';
import AboutUs from './about-us';
import Strip from './strip';
import Service from './service';
import ServTestimonials from './servtestimonials';
import TopClients from './topclients';
import BlogStart from './blogstart';
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import './index.css'
import { Helmet } from "react-helmet";
const AccruonHomePAge = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
    return (
      <>
        <Helmet>
          <title>Tax Consultants in UAE | Accruon Consultant LLC</title>
          <meta
            name="description"
            content="Accruon Consultant LLC is a leading Tax Consultants in UAE with a team of professional chartered accountants, cost and management accountants"
          />
          <link rel="canonical" href="https://accruonconsultants.com" />
        </Helmet>
        {/* <NavBar></NavBar> */}
        <BannerSection></BannerSection>
        <AboutUs></AboutUs>
        <Strip></Strip>
        <Service></Service>
        <ServTestimonials></ServTestimonials>
        <TopClients></TopClients>
        <BlogStart></BlogStart>
        {/* <Footer></Footer> */}
      </>
    );
}
export default AccruonHomePAge;