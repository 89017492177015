import React from "react";
import Counter from "../../../assets/components/counter";

export const Strip=()=> {
  const items = [
    { number: 35, text: "Team Members" },
    { number: 12000, text: "Audits" },
    { number: 9800, text: "Happy Clients" },
  ];

  return (
    <div className="count-up">
      {items.map((item, index) => (
        <div className="wrapper" key={index}>
          <div className="plus-one">
            <Counter className="counter" number={item.number} />
            <span className="plus-p">+</span>
          </div>
          <p className="strip-p">{item.text}</p>
        </div>
      ))}
    </div>
  );
}


