

import React from 'react'
import NavBar from '../../../components/navbar'
import Footer from '../../../components/footer'

import tax from '../../../../assets/images/services/SoftwareConsult-ImageRight.webp'
import ContactSectionPages from '../../../components/contact'
import { Helmet } from 'react-helmet'
function SoftwareandERPConsultingPage() {
    return (
      <>
        <Helmet>
          <title>Software and ERP Consulting | Accruon Consultant LLC </title>
          <meta name="description" content="" />
          <link
            rel="canonical"
            href="https://accruonconsultants.com/services/software-and-ERP-consulting"
          />
        </Helmet>
        {/* <NavBar></NavBar> */}

        <section class="about container margin-top1">
          <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12 md-pb">
              <div class="title-wrapper align-left">
                <h2 class="left h1-b">
                  <span>Service details</span>
                </h2>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 md-pb">
              <h3 class="pb-3 about-h1"> Software and ERP Consulting </h3>
            </div>
            <div class="col-lg-6 col-md-12 md-pb">
              <h3 class="pb-3 about-h2" style={{ textAlign: "left" }}>
                {" "}
                We provide solutions that are tailored to your company’s needs.{" "}
              </h3>

              <p class="about-p1">
                <strong>Accruon Consultant</strong>
                &nbsp;provides customers with bespoke software development & ERP
                solutions jointly with our software company accruon.ae . We’ve
                established a penchant for custom software solutions over the
                years, and we streamline corporate processes, functions, and
                overall projects with our strategic low-risk strategy. Our team
                of skilled software developers is capable of delivering
                high-performing apps that adhere to industry best practises in
                terms of quality assurance and business needs.
              </p>
            </div>
            <div class="col-lg-6 col-md-12 md-pb">
              <img src={tax} class="mob-img" alt="" />
            </div>
            <div class="col-lg-12 col-md-12 md-pb">
              <h3 class="pb-3 about-h2 left-h2" style={{ textAlign: "left" }}>
                {" "}
                Steps in the development of software{" "}
              </h3>
              <p class="about-p1">
                &#x2713; Compile a list of customer requirements
              </p>
              <p class="about-p1">&#x2713; Choosing a research approach</p>
              <p class="about-p1">&#x2713; Construct a structure</p>
              <p class="about-p1">&#x2713;Create a design</p>
              <p class="about-p1">&#x2713;Create a model</p>
              <p class="about-p1">&#x2713;Develop the software</p>
              <p class="about-p1">&#x2713;Research and development</p>
              <p class="about-p1">&#x2713;Correct the flaws</p>
              <p class="about-p1">&#x2713;Install the programme</p>
            </div>
          </div>
        </section>
        <ContactSectionPages />
        {/* <Footer></Footer> */}
      </>
    );
}

export default SoftwareandERPConsultingPage