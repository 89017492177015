import React from "react";
import NavBar from "../../../components/navbar";
import Footer from "../../../components/footer";

import tax from "../../../../assets/images/services/Excise-Tax-Related-Services.webp";
import ContactSectionPages from "../../../components/contact";
import { Helmet } from "react-helmet";
function ExciseTaxRelatedServicesPage() {
  return (
    <>
      <Helmet>
        <title>Excise Tax Related Services | Accruon Consultant LLC </title>
        <meta name="description" content="" />
        <link
          rel="canonical"
          href="https://accruonconsultants.com/services/excise-tax-related-services"
        />
      </Helmet>
      {/* <NavBar></NavBar> */}

      <section class="about container margin-top1">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-12 md-pb">
            <div class="title-wrapper align-left">
              <h2 class="left h1-b">
                <span>Service details</span>
              </h2>
              <h3 class="pb-3 about-h1">Excise Tax Related Services</h3>

              <p class="about-p1">
                &nbsp;The UAE government levies an indirect tax on commodities
                that are considered hazardous to human health or the
                environment. The primary goal of enacting an excise tax is to
                reduce the consumption of certain items, hence increasing
                government revenue that can be spent on the general population.
              </p>
              <p class="about-p1">
                The Excise Tax Federal Decree-Law No. (7) of 2017 was the first
                to impose an excise tax on specified items in the UAE, with
                effect from October 1, 2017. Following Cabinet Decision No. 52
                of 2019, a few more products were added to the scope of excise
                duty with effect from December 1, 2019. All taxable entities in
                the UAE are required to register for excise tax.
              </p>
            </div>
          </div>

          <div class="col-lg-6 col-md-12 md-pb">
            <img src={tax} class="mob-img" alt="Excise Tax Related Services" />
          </div>

          <h3 class="pb-3 about-h1">UAE Excise Taxable Goods</h3>
        </div>
        <section class="about container d-c1">
          <div class="list-service">
            <ol>
              <li>
                {" "}
                <div class="arrow-triangle left"></div>Carbonated Beverages –
                Any aerated beverage
              </li>
              <li>
                {" "}
                <div class="arrow-triangle left"></div> Energy Drink – Any
                beverage that is marketed or sold as an energy drink and
                contains stimulant substances that provide mental and physical
                stimulation{" "}
              </li>
              <li>
                {" "}
                <div class="arrow-triangle left"></div>Tobacco and Tobacco
                Products – Includes all items listed in Schedule 24 of the GCC
                Common Customs Tariff, including electrically heated cigarettes
              </li>
              <li>
                {" "}
                <div class="arrow-triangle left"></div> Electronic Smoking Tools
                and Devices – This category includes all electronic smoking
                tools and devices, whether or not they contain nicotine or
                tobacco
              </li>
              <li>
                {" "}
                <div class="arrow-triangle left"></div> Liquids Used in
                Electronic Smoking Devices and Tools – All liquids used in
                electronic smoking devices and tools, whether or not they
                contain nicotine or tobacco
              </li>
              <li>
                {" "}
                <div class="arrow-triangle left"></div> Sweetened Drinks – Any
                beverage that has been sweetened. A supply of sugar or other
                sweetness is added, as established by the GCC Organization’s
                Standard 148 and Standard 995
              </li>
              <li>
                {" "}
                <div class="arrow-triangle left"></div> Flavourless Water that
                has been aerated is not included in the category of carbonated
                drinks
              </li>
              <li>
                {" "}
                <div class="arrow-triangle left"></div> Alcoholic drinks are not
                included in the definition of excisable goods
              </li>
              <li>
                {" "}
                <div class="arrow-triangle left"></div> Drinks containing at
                least 75%
              </li>
            </ol>
          </div>
        </section>

        {/* <p class="about-p1">
          <p>
            <mark>a.</mark> milk or milk substitutes are not included in the
            definition of sweetened drink
          </p>
          <p>
            <mark>b.</mark> Baby food or baby formula
          </p>
          <p>
            <mark>c.</mark> Beverages for special dietary requirements or
            medical applications
          </p>
        </p> */}

        <div class="row justify-content-center">
          <p class="about-h1">Who needs to register for Excise Tax in UAE?</p>
        </div>
        <p class="about-p1">
          <strong>&#x2022; Excise products manufacturers</strong>
        </p>
        <p class="about-p1">
          <strong>&#x2022; Excise goods importers</strong>
        </p>
        <p class="about-p1">
          <strong>&#x2022; Excise product stockpilers</strong>
        </p>
        <p class="about-p1">
          <strong>
            &#x2022; Excise goods must be handled by warehouse keepers
          </strong>
        </p>

        <p class="about-p1">
          When applying for Excise Tax in the UAE, you must provide certain
          information and papers:
        </p>
        {/* <p class="about-p1">
          The following information should be provided by an applicant when
          registering for Excise Tax:
        </p> */}

        <p class="about-p1">
          &#x2713; Company information as well as legal documentation
        </p>

        <p class="about-p1">
          &#x2713; Details and paperwork for Authorized Signatories
        </p>

        <p class="about-p1">&#x2713; Financial Information</p>
        <p class="about-p1">&#x2713; Information about business activity</p>
        <p class="about-p1">
          &#x2713; If relevant, details about customs registration
        </p>
        <p class="about-p1">&#x2713; A list of the company’s excisable items</p>

        <div class="row justify-content-center">
          <p class="about-h1">
            Our Role as an Excise Tax Advisory Firm in the UAE
          </p>
        </div>
        <p class="about-p1">
          Accruon Consultant is a renowned tax consulting firm in the UAE. We
          house individuals that have in-depth knowledge and practical
          experience in Excise Tax, having established our presence in the
          market as VAT consultants. We can help you register for Excise Tax,
          file Monthly Returns, register your items with the Federal Tax
          Authority, and provide advice on how to comply with the UAE Excise Tax
          Law.{" "}
        </p>
      </section>

      <ContactSectionPages></ContactSectionPages>

      {/* <Footer></Footer> */}
    </>
  );
}

export default ExciseTaxRelatedServicesPage;
