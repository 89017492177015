import React from 'react'
import instagram from "../../../assets/images/insta.webp";
import linkDin from "../../../assets/images/linkdin.webp";
import twitter from "../../../assets/images/twitter.webp";
import facebook from "../../../assets/images/fb.webp";
import yutube from "../../../assets/images/you-tube.webp";
import { NavLink } from "react-router-dom";
export const SocialMedia = () => {
    const socialMediaLinks = [
      { url: "https://www.instagram.com/accruon_consultant", icon: instagram },
      {
        url: "https://www.linkedin.com/company/81844621/admin/feed/posts",
        icon: linkDin,
      },
      { url: "https://twitter.com/accruon_uae", icon: twitter },
      { url: "https://www.facebook.com/accruonconsultant", icon: facebook },
      { url: "https://www.youtube.com/@AccruonConsultants", icon: yutube },
    ];
  return (
    <div className="social1">
      {socialMediaLinks.map(({ url, icon }) => (
        <NavLink key={url} to={url} target="_blank" className="social-ic">
          <img src={icon} alt="" />
        </NavLink>
      ))}
    </div>
  );
}
