import React from 'react'
import headerDropDownData from "../../../utils/headerDropdownData.json"
import { useHeader } from '../hook'
import { Dropdown } from './Dropdown';
const ContentsWrapper = ({ children }) => {
    const { navShow2 } = useHeader();
    return (
        <section className="header2"><nav className={navShow2 == true ? "navbar2 active" : "navbar2"}>{children }</nav></section>
    )
}
export const BottomSection = () => {
  
  return (
    <ContentsWrapper>
      {headerDropDownData.map((item,index) => (
        <Dropdown key={index} title={item?.title} contents={item?.contents} />
      ))}
    </ContentsWrapper>
  );
}
