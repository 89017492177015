import React from 'react'
import accruon from "../../../assets/images/home/accruonconsult.webp";
import { CompanyLogo } from '../../../shared/components/CompanyLogo';
import { NavigationBar } from './NavigationBar';
const ContentsWrapper = ({ children }) => {
  return (
    <section className="header">
      <div className="container head-main">{children}</div>
    </section>
  );
};
export const TopSection = () => {
    return (
      <ContentsWrapper>
            <CompanyLogo image={accruon} />
            <NavigationBar/>
      </ContentsWrapper>
    );
}
