import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import { saveCustomerContactsForm } from "../../api/contact-us";
import NavBar from "../components/navbar";
import Footer from "../components/footer";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Button, notification, Space } from "antd";

import FristBuilding from "../../assets/images/fi-sr-building.webp";
import mailContact from "../../assets/images/mailcontact.webp";
import Phonecall from "../../assets/images/phone11.webp";
import Othercontact from "../../assets/images/othercontact.webp";
import { Helmet } from "react-helmet";
function ContactUsPage() {
  const { register, handleSubmit, reset } = useForm();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [api, contextHolder] = notification.useNotification();
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: "Success",
      description: "Thank you for choosing us",
      placement:"bottomRight",
    });
  };
  // api call to save the form fields
  const saveForm = useMutation({
    mutationFn: (payload) => saveCustomerContactsForm(payload),
    onMutate: () => {
      messageApi.open({
        key: "saveForm",
        type: "loading",
        content: "Sending...",
      });
    },
    onSuccess: () => {
      // messageApi.open({
      //   key: "saveForm", // key same as previous message so that it is replaced
      //   type: "success",
      //   content: "Send successfully!",
      //   duration: 2,
      //   placement: "bottomRight",
      // });
      openNotificationWithIcon("success");
      reset();
    },
    onError: () => {
      messageApi.open({
        key: "saveForm",
        type: "error",
        content: "Failed to send!",
      });
    },
  });
  const handleUserDetails = (values) => {
    const data = {
      fullName: values.fullName + " " + values.lastName,
      customerLastName: values.lastName,
      email: values.email,
      phone: values.phone,
      type: "contact",
      description: values.description,
      address: values.address,
    };
    saveForm.mutate(data);
    
  };
  return (
    <>
      <Helmet>
        <title>Contact Us| Accruon Consultant LLC</title>
        <meta name="description" content="" />
        <link
          rel="canonical"
          href="https://accruonconsultants.com/Contact-Us"
        />
      </Helmet>
      {/* <NavBar></NavBar> */}
      {/* {messageContextHolder} */}
      {contextHolder}
      <section class="main">
        <h2 class="left h1-b">
          <span>Contact Us</span>
        </h2>
        <section class="contact" id="contact">
          <div class="container">
            <div class="heading text-center">
              <h1 class="about-h">Get in touch</h1>
              <p>
                If you are interested in working with us then please drop us a
                line, <br />
                we would love to hear from you.
              </p>
            </div>
            <div class="row">
              <div class="col-md-4 address-side">
                <div class="content">
                  <div class="info">
                    <div class="name-img">
                      <img alt="" src={Othercontact} className=""></img>
                      <h4 class="d-inline-block">Head Office</h4>
                    </div>

                    <p class="para-img">
                      <span>
                        Accruon Consultant LLC, M6-29, Mezzanine Floor, ACICO
                        Business Park, Port Saeed, Deira, Dubai, UAE
                      </span>
                    </p>
                  </div>

                  <div class="info">
                    <div class="name-img">
                      <img alt="" src={mailContact} className=""></img>
                      <h4 class="d-inline-block">Email Us</h4>
                    </div>
                    <p class="para-img">
                      <span>mail@accruonconsultants.com</span>
                    </p>
                  </div>

                  <div class="info">
                    <div class="name-img">
                      <img alt="" src={Phonecall} className=""></img>
                      <h4 class="d-inline-block">Call Us</h4>
                    </div>
                    <p class="para-img">
                      <span>
                        +971 52 913 7700
                        <br />
                        +971 67 437 356
                      </span>
                    </p>
                  </div>

                  <div class="info">
                    <div class="name-img">
                      <img alt="" src={FristBuilding} className=""></img>
                      <h4 class="d-inline-block">Other Offices</h4>
                    </div>
                    <p class="para-img">
                      <div className="b1-m">
                        <span>
                          <h6>
                            <span class="other-place">USA</span>
                            <br />
                            <span className="other-para">
                              Glen Allen, Virginia USA
                            </span>
                          </h6>
                        </span>
                      </div>
                      <div className="b1-m">
                        <span>
                          <h6>
                            <span class="other-place">BENGALURU</span>
                            <br />
                            <span className="other-para">
                              Money Chambers, 206, 2nd Floor, KH Road, Double
                              Rd, Shanti Nagar, Bengaluru, Karnataka 560027
                            </span>
                          </h6>
                        </span>
                      </div>
                      <span>
                        <h6>
                          <span class="other-place">INFOPARK – KOCHI</span>
                          <br />
                          <span className="other-para">
                            Ground Floor, Carnival Infopark, Phase-1, Infopark,
                            Kochi, Kerala India – 682042
                          </span>
                        </h6>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <form
                  class="contact-form"
                  onSubmit={handleSubmit(handleUserDetails)}
                >
                  <div class="row">
                    <div class="col-sm-6">
                      <label>First name</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Type Here"
                        {...register("fullName")}
                        required
                      />
                    </div>
                    <div class="col-sm-6">
                      <label>Last name</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Type Here"
                        {...register("lastName")}
                        required
                      />
                    </div>
                    <div class="col-sm-12">
                      <label>Email address</label>
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Type Here"
                        {...register("email")}
                        required
                      />
                    </div>
                    <div class="col-sm-12">
                      <label>Phone</label>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Type Here"
                        {...register("phone")}
                        required
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Message(Optional)</label>
                    <textarea
                      class="form-control txt11"
                      rows="5"
                      id="comment"
                      placeholder="Type Here"
                      {...register("description")}
                      required
                    ></textarea>
                  </div>
                  <button class="btn btn-block" type="submit">
                    Send
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </section>
      {/* Map */}
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7216.772883475333!2d55.3333229!3d25.2575826!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5ce09d4580dd%3A0x74bc22958ce211fe!2sACICO%20Business%20Park%20-%20Al%20Khabaisi%20-%20Dubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1718167295913!5m2!1sen!2sin"
        style={{
          width: "100%",
          height: "450px",
          border: "0",
        }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      {/* map end */}
      {/* <Footer></Footer> */}
    </>
  );
}
export default ContactUsPage;
