import React from 'react'

export const Services = () => {
  const ServiceList = [
    {
      name: "Corporate Tax in UAE",
      path: "/services/corporate-tax-in-uae",
    },
    {
      name: "ESR",
      path: "/services/economic-substance-regulations",
    },
    {
      name: "VAT Consultants in UAE",
      path: "/services/vat-consultants-in-uae",
    },
    {
      name: "Accounting & Bookkeeping",
      path: "/accounting-bookkeeping-page",
    },
    {
      name: "Audit & Assurance",
      path: "/services/audit-and-assurance-service-in-uae",
    },
    {
      name: "Excise Tax Related Services",
      path: "/services/excise-tax-related-services",
    },
    {
      name: "Software and ERP Consulting",
      path: "/services/software-and-ERP-consulting",
    },
  ];
  return (
    <React.Fragment>
      <div className="col-xl-3 col-lg-5 box">
        <h5 className="about-hs">Services</h5>
        {ServiceList?.map((service,index) => (
          <a
            key={index}
            href={service?.path}
          >
            {service?.name}
          </a>
        ))}
      </div>
    </React.Fragment>
  );
}
