
import axios from "axios"
const { REACT_APP_BASE_API_URL } = process.env
console.log(process.env)
export function saveCustomerContactsForm(data) {

    data = {
        ...data
    }
    return axios.post(`${REACT_APP_BASE_API_URL}/addCustomerContacts`, data)
}