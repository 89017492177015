import React from 'react'

export const Menu = () => {
  const MenuList = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "About Us",
      path: "/About-Us",
    },
    {
      name: "Our Team",
      path: "/Our-Team",
    },
    {
      name: "Services",
      path: "/Services",
    },
    {
      name: "Blogs",
      path: "/Blogs",
    },
    {
      name: "Career",
      path: "/Careers",
    },
    {
      name: "Contact Us",
      path: "/Contact-Us",
    },
  ];
  return (
    <React.Fragment>
      <div className="col-xl-2 col-lg-5 box">
        <h6 className="about-hs">Menu</h6>
        {MenuList?.map((menuItem,index) => (
          <a
            key={index}
            href={menuItem?.path}
          >
            {menuItem?.name}
          </a>
        ))}
      </div>
    </React.Fragment>
  );
}
