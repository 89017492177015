import React from 'react'
import Othercontact from "../../../assets/images/othercontact.webp";
import { NavLink } from "react-router-dom";

export const ContactAddresses = () => {
    const contactAddresses = [
      { text: "Glen Allen, Virginia USA" },
      {
        text: "Money Chambers, 206, 2nd Floor, KH Road, Double Rd, Shanti Nagar, Bengaluru, Karnataka 560027",
      },
      {
        text: "Ground Floor, Carnival Infopark, Phase-1, Infopark, Kochi, Kerala India – 682042",
        url: "https://accruontechnologies.com/",
      },
    ];
  return (
    <React.Fragment>
        {contactAddresses.map(({ text, url }) => (
                  <div key={text} className="foot-ic">
                    <div>
                      <img alt="" src={Othercontact} />
                    </div>
                    {url ? (
                      <NavLink to={url} target="_blank">
                        {text}
                      </NavLink>
                    ) : (
                      <div>{text}</div>
                    )}
                  </div>
                ))}
    </React.Fragment>
  )
}
