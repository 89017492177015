import React from "react";
import "react-multi-carousel/lib/styles.css";
import leftarrow from "../../../assets/images/home/left-arrow.webp"
import rightarrow from "../../../assets/images/home/right-arrow.webp"
import cleantel from "../../../assets/images/home/cleantel.webp"
import op3 from "../../../assets/images/home/op-3.webp"
import steelmart from "../../../assets/images/home/steel-mart.webp"
import './index.css'
import { NavLink } from "react-router-dom";
import { CarouselComponent } from "../../../shared/components/CarouselComponent";
function ServTestimonials() {
   
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 2,
    
          slidesToSlide: 2
        },
        desktop: {
          breakpoint: { max: 3000, min: 1200 },
          items: 2,
          slidesToSlide: 2
        }, 
        
        tablet: {
          breakpoint: { max: 1300, min: 880 },
          items: 2,
          slidesToSlide: 2
    
        },
        mobile: {
          breakpoint: { max: 880, min: 0 },
          items: 1
        }
  };
    return (
      <>
        <section class="pt-5 circle-1 main">
          <h2 class="left h2-b h2-b1">
            {" "}
            <span>Our Testimonials</span>
          </h2>
          <h2 class="h-blog">Client Testimonials on Our Services</h2>
          <h2 class="open-page"></h2>
          <div class="container mt-4">
            {/* <Carousel
              responsive={responsive}
              showDots={true}
              autoPlay={true}
              autoPlaySpeed={3400}
              infinite={true}
              rewind={true}
            > */}
            <CarouselComponent
              responsive={responsive?responsive:{}}
              testimonials={true}
              viewDots={true}
              autoPlaySpeed={3400}
              autoPlay={true}
            >
              <div
                style={{
                  width: "400px",
                }}
                class="carasoulpage"
              >
                <img alt="" src={leftarrow} class="pb-4 comms" />
                <p class="text-muted text-m">
                  Working with “Accruon Consultant” has been a game-changer for
                  our business. Their professionalism, deep industry knowledge,
                  and commitment to delivering results are truly commendable.
                  Accruon team of experts provide invaluable guidance and
                  support, helping us streamline our operations, optimize our
                  processes, and achieve significant cost savings. We highly
                  recommend “Accruon Consultant” to any organization seeking
                  top-notch consulting services.
                </p>
                <p></p>

                <div>
                  {" "}
                  <img alt="" src={rightarrow} class="pb-4 comms arrow2" />
                </div>

                <div class="">
                  <div class="p">
                    <div class="d-flex align-items-center pt-3">
                      <div class="author-img mr-3">
                        <NavLink to="https://cleantel.me/" target="_blank">
                          {" "}
                          <img alt="" src={cleantel} class="img-lo" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* 2 */}

              <div
                style={{
                  // height:'380px',
                  width: "400px",
                }}
                class="carasoulpage"
              >
                <img alt="" src={leftarrow} class="pb-4 comms" />

                <p class="text-muted text-m">
                  I cannot thank “Accruon Consultant” enough for their
                  exceptional consulting services. The dedication, expertise,
                  and collaborative approach of the entire team have exceeded
                  our expectations. Their strategic insights and innovative
                  solutions have transformed our business. With their guidance,
                  we were able to identify untapped opportunities, enhance our
                  market positioning, and drive substantial growth.
                </p>
                <p></p>
                <div>
                  {" "}
                  <img alt="" src={rightarrow} class="pb-4 comms arrow2" />
                </div>

                <div></div>
                <div class="p1">
                  <div class="p">
                    <div class="d-flex align-items-center pt-3">
                      <div class="author-img mr-3">
                        <NavLink to="https://op3global.com/" target="_blank">
                          {" "}
                          <img alt="" src={op3} class="img-lo" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* 3 */}

              <div
                style={{
                  // height:'380px',
                  width: "400px",
                }}
                class="carasoulpage"
              >
                <img alt="" src={leftarrow} class="pb-4 comms" />
                <p class="text-muted text-m">
                  Choosing Accruon Consultant was the best decision we made for
                  our organization. They conducted a thorough analysis of our
                  business, identified areas for improvement, and provided
                  practical recommendations. Their actionable strategies helped
                  us overcome challenges, improve our efficiency, and achieve
                  measurable results. We are incredibly grateful for their
                  professionalism, integrity, and unwavering support throughout
                  the engagement..
                </p>
                <p></p>

                <div>
                  {" "}
                  <img alt="" src={rightarrow} class="pb-4 comms arrow2" />
                </div>
                <div class="">
                  <div class="p">
                    <div class="d-flex align-items-center pt-3">
                      <div class="author-img mr-3">
                        <img alt="" src={steelmart} class="img-lo" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CarouselComponent>
            {/* </Carousel> */}
          </div>
        </section>
      </>
    );
}

export default ServTestimonials